.myfav-page {
    ul {
        list-style: none;
        margin: 0 !important;
    }

    a .fa {
        color: #5e5e5e;
    }

    .fa-heart {
        color: #c00a0a !important;
        font-size: 16px !important;
    }

    body {
        font-family: 'Lato', sans-serif;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.6em;
        color: #656565;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    #myfav-page img {
        height: auto;
    }

    .create-look a {
        color: #000000;
        margin-bottom: 10px;
        font-size: 14px;
    }


    /* dropdown area  */
    .dropdown-area {
        display: flex;
        /* justify-content: space-evenly; */
        /* margin-left: 10px; */
    }

    .dropdown-area .clear-filter a {
        color: #000000;
        font-size: 14px;
        margin-left: 10px;
    }

    .dropdown-area .gap {
        margin-left: 10px;
        height: 39px;
    }

    .dropdown-area.condition {
        font-size: 14px;
        color: #bb2222;
    }

    .dropdown-area .gap-1 {
        height: 10px;
    }

    .action-icon .fa {
        margin: 0 10px;
    }

    .mobile-btn,
    .custom-select {
        display: none;
    }

    .dp-margin-alternative {
        margin: 0 6px;
    }

    .dropdown-area .dropdown ul li {
        display: block;
        color: #5e5e5e;
    }

    .dropdown-area .dropdown .btn,
    .dropdown-area select {
        width: 120px;
        height: 40px;
        font-size: 14px;
        display: flex;
        align-items: center;
        border-radius: 0;
        padding: 0 0 0 7px;
    }

    .dropdown-area .dropdown .caret,
    .dropdown-area select {
        color: #5e5e5e;
    }

    .dropdown-area .clear-filter a {
        /* gap: 30px; */
        color: #000000;
        font-size: 14px;
        margin-left: 10px;
    }

    .dropdown-area .clear-filter {
        margin-top: 7px;
    }

    .dropdown-area #gender2 {
        border: .5px solid black;
    }

    .dropdown #gender2option {
        font-size: 40px;
    }


    /* product main  */
    .product-main .col-lg-3 {
        padding-right: 5px;
        padding-left: 5px;
        margin-bottom: 10px;
    }

    .product-cnt {
        padding: 0px;
        margin-bottom: 15px;
        position: relative;
    }

    .product-cnt strike {
        font-size: 100%;
        font-size: 12px;
    }

    .product-cnt small {
        font-size: 100%;
        font-size: 12px;
    }

    .product-cnt .prod-cashbck {
        color: #c7c5c5;
    }

    .product-cnt img {
        width: 100%;
        max-width: 100%;
        max-height: 100%;
    }

    .product-cnt .post-heading .stor_name {
        display: none;
        font-size: 16px;
        font-weight: normal;
        color: #c7c5c5;
        cursor: pointer;
    }

    .product-cnt .post-heading:hover .prod-cashbck {
        display: none;
    }

    .product-cnt .post-heading:hover .stor_name {
        display: block;
    }

    .css-ik6y5r{
        background: whitesmoke;
    }
    /* container desc  */
    .container-desp {
        width: 88%;
        padding: 0px 5px;
        margin: 10px auto 0px auto;
        overflow: hidden;
        font-size: 13px;
        font-weight: 400;
        color: #656565;
    }

    .container-desp strike+small {
        color: #ff0000;
        font-weight: normal;
        margin-left: 5px;
    }

    .container-desp strike+small:hover {
        color: #ff0000;
    }

    .container-desp b {
        color: #717171;
        font-size: 11px;
    }

    .container-desp small {
        color: #0e0f0f;

    }

    .container-desp small:hover {
        color: #0e0f0f;
        text-decoration: underline;
        cursor: pointer;
    }

    .container-desp p {
        margin: 0;
    }

    /* Bottom header  */
    .bottom-header {
        padding: 2px 4px;
        border-top: 1px solid #d5d0d0;
        border-bottom: 1px solid #d5d0d0;
        margin-bottom: 20px;
    }

    .bottom-header .left-side {
        display: flex;
        align-items: center;
    }

    .bottom-header .left-side .menu-icon {
        color: #000;
        font-size: 20px;
    }

    .bottom-header .left-side .heart-icon {
        color: #bb2222;
        font-size: 18px;
        margin-left: 15px;
    }

    .bottom-header .bottom-header-link {
        display: flex;
        justify-content: space-between;
    }

    .bottom-header .bottom-header-link li a {
        color: #000;
        text-transform: capitalize;
        font-size: 13px;
    }


    .bottom-header .bottom-header-form-check {
        display: flex;
        justify-content: flex-end;
        color: #000;
    }

    .bottom-header-form-check label {
        font-weight: 300;
        font-size: 12px;
    }

    .my-fav img {
        width: auto !important;
        height: 180px !important;
        justify-content: center;
    }


    .checkbox-black input[type="checkbox"]+label::before {
        background-color: #000000;
        border-color: #000000;
    }

    .checkbox-black input[type="checkbox"]:checked+label::after {
        color: #fff;
    }


    /* Sidemenu css */
    .parent {
        border-bottom: 1px solid #d5d0d0;

    }

    .parent li {
        margin-bottom: 20px;
    }

    .parent .title {
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 14px;
        letter-spacing: 1px;
        color: #000;
    }

    .parent .title-sub2 {
        font-size: 12px;
        letter-spacing: 2px;
        color: #000;
        padding-top: 10px;
        font-weight: 500;
    }

    .parent-cat-2 .child {
        color: #000;
    }

    .parent-cat-3 {
        margin-top: 10px;
    }

    .sidebar-navigation .quick-link {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 700;
        padding-top: 10px;
    }

    .sidebar-navigation .quick-link li {
        color: #000;
        margin-bottom: 20px;
        border-bottom: 1px solid #d5d0d0;
    }

    .sidebar-navigation .quick-link li a {
        color: #000;
        padding-top: 10px;
    }

    .parent .subMenuColor1 li,
    .parent .subMenuColor2 li {
        margin-bottom: 0;
    }

    .parent .subMenuColor2 li a {
        padding: 2px 0;
        color: #5e5e5e;
        font-size: 14px;
    }

    .dropdown-menu>li>a {
        font-size: 12px;
    }

    .bottom-header-form-check input[type="checkbox"] {
        margin: 0 5px;
    }

    .bottom-header-form-check .form-check {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    input::placeholder {
        text-transform: capitalize;
        font-size: 14px;
    }

    .box-check {
        display: block;
        position: relative;
        padding-left: 20px;

        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .box-check input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 4px;
        left: 0;
        height: 15px;
        width: 15px;
        background-color: #eee;
        border: 1px solid #000;
    }

    /* On mouse-over, add a grey background color */
    .box-check:hover input~.checkmark {
        background-color: #ccc;
    }

    /* When the checkbox is checked, add a black background */
    .box-check input:checked~.checkmark {
        /* background-color: #000; */
        background-color: #fff;
        border: 1px solid #000;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .box-check input:checked~.checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .box-check .checkmark:after {
        left: 4px;
        top: 1px;
        width: 5px;
        height: 9px;
        border: solid #000;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .mobile-menu-cross-icon {
        float: right;
        margin: 10px 0;
    }

    .filter-btn,
    .custom-select {
        display: none;
    }

    .post-image .post-heading {
        font-size: 12px;
        margin-bottom: 5px;
    }

    .product-cnt:hover .hover-icon-product-image {
        display: flex;
        justify-content: space-evenly;
        visibility: visible;
    }

    .hover-icon-product-image {
        visibility: hidden;
    }

    .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 999;
        top: 0;
        right: 0;
        background-color: #fff;
        overflow-x: hidden;
        transition: 0.5s;
        padding-top: 50px;
        /* padding-left: 10px; */
        /* padding-right: 29px; */
    }

    .sidenav .closebtn {
        position: absolute;
        top: 10px;
        right: 20px;
        font-size: 36px;
        margin-left: 50px;
    }

    .sidenav .box-check {
        font-size: 16px;
        font-weight: lighter;
        margin: 5px;
        color: #000;
    }

    .sidenav .box-check .checkmark::after {
        border-width: 0 1px 1px 0;
    }

    .sidenav h5 {
        padding: 7px 0;
        font-size: 16px;
        font-weight: 500;
    }

    .btn-dark {
        background-color: #000;
        border: none;
    }

    /* panel  */
    .panel {
        margin: 10px 10px 20px;
        box-shadow: none !important;
    }

    .panel-default {
        border: none;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
    }

    .panel-group .panel {
        border-radius: 0;
    }

    .panel-default>.panel-heading {
        color: #000;
        background-color: transparent;
        margin-bottom: 10px;
    }

    .panel-title>a:before {
        float: right !important;
        content: "\2212";
        padding-right: 5px;
    }

    .panel-title>a.collapsed:before {
        float: right !important;
        content: "\002B";
    }

    .custom-control {
        min-height: 2.5rem;
    }

    .custom-select {
        /* background: #e9e9ed; */
        border: 1px solid #e9e9ed;
        padding: 7px 10px;
    }


    @media (min-width: 992px) {
        .col-md-1-5 {
            width: 20%;
        }
    }

    @media (max-width: 992px) {
        .bottom-header-form-check {
            display: none !important
        }

    }

    @media (max-width:426px) {
        .mobile-btn {
            margin-left: 15px;
        }
    }

    #sortModal {
        position: absolute;
        top: 20%;
    }

    #sortModal .modal-footer,
    #sortModal .modal-header {
        border: none;
    }

    #sortModal .custom-control-label {
        font-weight: lighter;
        padding: 0 7px;
        font-size: 16px;
    }

    .custom-select {
        background: auto;
        background-color: auto;
        border: 1px solid #e9e9ed;
    }

    aside {
        position: relative;
        margin-bottom: 40px;
    }

    .sidebar-nav {
        float: left;
        width: 100%;
    }

    .right-sidebar {
        border-left: 1px solid #e9e9e9;
        padding: 0 0 0 30px;
    }

    .left-sidebar {
        border-right: 1px solid #e9e9e9;
        padding: 0px 20px 0 0;
        background-color: #fff;
        z-index: 999
    }

    .left-sidebar .widget h3,
    .left-sidebar .widget .widget-content {
        padding-right: 20px;
    }

    aside .widget {
        margin-bottom: 40px;
    }


    @media (max-width:767px) {
        /* FILTER :: if you hide 1024 to 768 then go bootsrtap.css line 4194 change 1025 to 768*/

        .mobile-btn {
            display: flex;
            margin-left: 15px;
        }

        .mobile-btn button {
            margin-right: 15px;
            width: 100px;
            border-radius: 0;
        }

        .col-md-1-5 {
            width: 50%;
        }

        .create-look,
        .action-icon {
            display: none !important
        }

        #body_content {
            padding: 16px 0 0 0;
        }

        .mo-filter-sort {
            display: block;
        }

        .mobile-filter-close {
            display: block;
        }

        .hide-mob {
            display: none !important;
        }

        .filter-main {
            float: left;
            width: 100%;
        }

        /* .filter-nav{display:initial;float:left;width:100%;background:#f8f8f8;position:absolute;left:0px;padding:0px;z-index: 999;} */
        .sort-nav {
            position: absolute;
            right: 0px;
            float: right;
            width: calc(49.8% - 15px);
            margin-right: 15px;
            display: initial;
            padding: 0px;
        }

        #mySort .nav>li.dropdown.open .dropdown-menu {
            width: 100%;
            background: #fff;
            box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
            z-index: 9999;
            position: absolute;
        }

        .sort-nav .navbar-nav>li {
            float: none;
        }

        .sort-nav .navbar-nav {
            margin: 0px;
        }

        #mySort ul.nav li.dropdown a {
            font-size: 14px;
        }

        #myFilter,
        #mySort {
            padding: 0px;
            border: 0px;
        }

        #myFilter .navbar-collapse {
            padding: 0px;
        }

        #myFilter .navbar-nav {
            margin: 0;
        }

        #myFilter .navbar-nav .open .dropdown-menu {
            position: absolute;
            padding: 1rem 2rem;
            background: #ffffff;
        }

        header ul.dropdown-menu {
            padding: 0% 1% 1% 4%;
        }

        .dropdown-area {
            display: none !important;
        }

        .filter-btn,
        .custom-select {
            display: block !important;
            border-radius: none;
            width: 100px;
        }

        .bottom-header .left-side {
            display: none !important;
        }

        .hover-icon-product-image {
            display: flex;
            justify-content: space-evenly;
            visibility: visible;
        }
    }

    .stores {

        /* width: 18%; */

        width: 145px;
        height: 36px;
        font-size: 12px;
        display: flex;
        align-items: center;
        border-radius: 0 !important;
        padding: 0 0 0 7px;

    }

    .multiselect {
        width: 200px;
    }

    .selectBox {
        position: relative;
    }

    .selectBox select {
        width: 100%;
        font-weight: bold;
    }

    .overSelect {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    #checkboxes {
        display: none;
        border: 1px #dadada solid;
    }

    #checkboxes label {
        display: block;
    }

    #checkboxes label:hover {
        background-color: #1e90ff;
    }

    .store {
        border: .5px solid #000;
        ;
    }

    .brand {
        margin-left: 10px;
        border: .5px solid #000;
        /* ; */
    }

    #sidebar .active-category {
        /* background-color: black;
        color: white; */
        text-decoration: underline;
    }

    @media (max-width:px) {}
}