/* Whishlist Modal */

.modal.whis-modal {
    display: none;
    position: fixed;
    z-index: 999999999;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.product-cnt .whis-modal {
    position: absolute;
    z-index: 999;
    /* width: 250px; */
    background-color: inherit;
    top: 30% !important;
}

.whis-modal .modal-content {
    background-color: #fefefe;
    margin: auto;
    border-radius: inherit;
    padding: 10px;
    border: .5px solid #000;
    /* width: 250px; */
    height: 100px;
}

.whis-modal .modal-content p {
    color: #000;
    text-align: left;
    font-size: 11px;
    margin-bottom: 10px;
}

.whis-modal .modal-content p b {
    float: left;
    width: 50px;
    padding-right: 10px;
    font-weight: 400;
}

#colorselectors .dropdown {
    width: 90%;
}


/* The Close Button */

.close {
    color: #656565;
    width: 16px;
    height: 16px;
    text-align: center;
    border-radius: 100%;
    cursor: pointer;
    position: absolute;
    right: 7px;
    top: -2px;
    font-size: 16px;
    font-weight: 400;
    opacity: 1 !important;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.dropdown-menu {
    border: inherit;
}


/* colorselector dropdown */

.dropdown-colorselector>.dropdown-menu {
    top: 80%;
    left: -7px;
    padding: 4px;
    min-width: 130px;
    max-width: 130px;
}


.dropdown-colorselector>.dropdown-menu>li {
    display: block;
    float: left;
    width: 20px;
    height: 20px;
    margin: 2px;
}

.dropdown-colorselector>.dropdown-menu>li>.color-btn {
    display: block;
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    border-radius: 0;
    position: relative;
    -webkit-transition: all ease 0.1s;
    transition: all ease 0.1s;
}

.dropdown-colorselector>.dropdown-menu>li>.color-btn:hover {
    text-decoration: none;
    opacity: 0.8;
    filter: alpha(opacity=80);
    -webkit-transform: scale(1.08);
    -ms-transform: scale(1.08);
    transform: scale(1.08);
}

.dropdown-colorselector>.dropdown-menu>li>.color-btn.selected:after {
    content: "\e013";
    font-family: 'Glyphicons Halflings';
    display: inline-block;
    font-size: 11px;
    color: #FFF;
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    line-height: 20px;
}

.btn-colorselector {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: #DDD;
    vertical-align: middle;
    border-radius: 0;
}

.dropdown-menu.dropdown-caret:before {
    border-bottom: 7px solid rgba(0, 0, 0, 0.2);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: "";
    display: inline-block;
    left: 9px;
    position: absolute;
    top: -7px;
}

.dropdown-menu.dropdown-caret:after {
    border-bottom: 6px solid #FFFFFF;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: "";
    display: inline-block;
    left: 10px;
    position: absolute;
    top: -6px;
}

.sizes {
    border: 1px solid #444;
    padding: 2px 5px 2px 5px;
    color: #000;
    margin-right: 5px;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 11px;
    text-align: center;
}

.sizes:hover {
    background-color: #000;
    color: #fff;
}

.buynow {
    border: 1px solid #444;
    padding: 2px 6px 2px 6px;
    margin-right: 5px;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 10px;
    width: 115px;
    font-size: 11px;
    color: #fff;
    background-color: #000;
    text-align: center;
    letter-spacing: 1px;
}

.addtocart {
    border: 1px solid #444;
    padding: 3px 5px 3px 5px;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 10px;
    width: 87px;
    font-size: 11px;
    color: #000;
    background-color: #fff;
    text-align: center;
    letter-spacing: 1px;
    border-radius: 2px;
}

.addtocart:hover {
    background-color: #efefef;
}

.addtocart .fa-heart:before {
    /* color: #ff0000 !important */
}

i>.far>.fa-heart:before:hover {
    color: #ff0000;
    cursor: pointer;
}

.register h2 {
    padding-top: 80px;
    color: #000;
}

.t-bg {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 15px;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    padding: 20px;
}

.product-cnt .whis-modal {
    position: absolute;
    z-index: 999;
    width: 230px;
    background-color: inherit;
    top: 54% !important;
    left: 3px;
}

.modal.whis-modal {
    display: none;
    position: fixed;
    z-index: 999999999;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.4);
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}

.action-button {
    margin-top: 25px
}

.action-button .buynow {
    padding: 3px 12px;
}


@media screen and (max-width: 600px) {
    .product-cnt .whis-modal {
        height: 100px;
        width: 175px;
        left: -9px;
        top: 68% !important;
        border-radius: 3px;
    }

    .whis-modal .modal-content {
        height: 110px
    }

    .whis-modal .modal-content p {
        margin-top: 0px;
    }

    .whis-modal .modal-content p b {
        margin-top: 10px;
    }

    .whis-modal .modal-content p select {
        width: 65%;
    }

    .whis-modal .modal-content p.action-button {
        /* display: flex; */
        flex-wrap: wrap;
        margin-top: 13px;
    }

    .whis-modal .modal-content p.action-button .buynow {
        border-radius: 2px;
    }
}