/* #contact_us {
    margin: 120px 0px;
} */


/* Turning off rounded box */

/* #contact_us input,
#contact_us a.btn,
#contact_us button {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    -ms-border-radius: 0 !important;
    -o-border-radius: 0 !important;
    border-radius: 0 !important;
    outline: 0 !important;
} */

/* heading styles */
/* 
#contact_us h3 {
    font-family: 'Lato', sans-serif;
    font-size: 27px;
    color: #000;
    font-weight: 400;
    margin-bottom: 47px;
    text-align: center;
    margin-left: -25px;
} */

@media (max-width: 767px) {
   #contact_us{
        margin: 60px 0;
    }
    .mb-3{
        text-align: center;
    }
}
@media (max-width: 470) {
   #contact_us{
        margin: 60px 0;
    }
    .mb-3{
        text-align: center;
    }
}


/* ******************************New CSS********************************************************* */
/* Author: Devansh */
    body {
        margin: 0;
        font-family: 'BrownLight';
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #363838;
        text-align: left;
        background-color: #fff;
        margin: 0;
        padding: 0;
    }

#floatingMessage {
    height: 200px;
    width: 100%;
    border-radius: 0 !important;
}

.form-floating {
    height: 30px;
}
.label-name{
    color: #454749;
}
.mt-20 {
    margin-top: 20px;
}
.form-control{
    border-radius: none;
    color: #000 !important;
}
/* .submit-button{
    width: 100%;
    background-color: black;
    color: white;
    border: none;
    margin-top: 200px;
    height: 40px;
    font-family:'BrownLight';
    letter-spacing: 1.5px;
    line-height: 25px;
    font-size: 14px;
} */
.button-msg{
    width: 100%;
    background-color: black;
    color: white;
    border: none;
    margin-top: 200px;
    height: 40px;
    font-family:'BrownLight';
    letter-spacing: 1.5px;
    line-height: 25px;
    font-size: 14px;
}
.h3-section {
    text-align: center;
}

.scroll {
    overflow-y: scroll;
    overflow-x: hidden;
    resize: none;
    border-radius: none;
}

/* input, textarea {
    border-radius: 0px!important;
    -moz-box-shadow: inset 0 0 0px #fff!important;
    -webkit-box-shadow: inset 0 0 0px #fff!important;
    box-shadow: inset 0 0 0px #fff!important;
    outline: none!important;
    -webkit-appearance: none!important;
    -moz-appearance: none!important;
} */
 
  