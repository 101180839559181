footer {
    padding: 35px 0 0 0;
    background-color: #fff;
    position: relative;
    z-index: 100;
    color: #bbb;
    border-top: 1px solid #595959;
}

footer ul li {
    list-style-type: none;
}

footer a {
    text-decoration: none;
    font-size: 13px;
    color: #696969;
}

footer .subscribe {
    float: left;
    width: 100%;
    margin-top: 7px;
}

footer a:hover {
    text-decoration: none;
    color: #000;
}

.icon-facebook-sign:before {
    color: #000;
    font-size: 19px;
    padding-right: 5px;
}

.icon-twitter-sign:before {
    color: #000;
    font-size: 19px;
    padding-right: 5px;
}

.icon-pinterest:before {
    color: #000;
    font-size: 19px;
    padding-right: 5px;
}

.icon-instagram:before {
    color: #000;
    font-size: 19px;
    padding-right: 5px;
}

footer h5.widgetheading {
    font-family: 'helveticaneuemedium', Arial, Helvetica, sans-serif;
    text-transform: none;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: #000;
    font-weight: 600;
    font-size: 13px;
}

footer .news-ltr h5 {
    text-align: center;
}

ul {
    padding-left: 0;
}

ul.link-list li {
    margin: 0;
    padding: 2px 0 2px 0;
    list-style: none;
}

ul.link-list li i {
    font-size: 20px;
    padding-right: 8px;
    color: #000000;
    width: 26px;
}

footer .widget i {
    color: #fff;
    font-size: 12px;
    position: relative;
    top: -1px;
}

footer h5 a:hover,
footer a:hover {
    text-decoration: none;
}

#sub-footer span {
    color: #666;
    font-size: 11px;
    margin-left: 30px;
}

/* .subscribe .input-append2 .span2 {
    float: left;
    padding: 7px 10px;
    width: calc(100% - 100px);
    border: 1px solid #666666;
    text-align: left;
    font-size: 14px;
    color: #696969;
} 

 .subscribe .input-append2 .btn-theme {
    float: left;
    width: 100px;
    color: white;
    background-color: #000;
    font-size: 13px;
    padding: 8px 12px;
    border-radius: 0px;
    margin-top: -63px;
 } 

.subscribe-btn{
    color: black
} */


.subscribe .subscribe-btn {
    width: 100px;
    color: white;
    background-color: black;
    margin-left: -30px;
}

#sub-footer ul.social-network {
    float: right;
}

.link-list footer ul.link-list li a {
    color: #444;
    font-weight: 300;
}

footer .widget .flickr_badge {
    width: 100%;
}

footer .widget .flickr_badge img {
    margin: 0 9px 20px 0;
}

ul.general {
    list-style: none;
    margin-left: 0;
}

.news-lt-mob {
    display: none;
}

/* .subscribe .input-append .btn-theme {
    color: #fff;
    background-color: #000;
    font-size: 17px;
    position: relative;
    top: -1px;
    left: -3px;
} */

.conct_app a {
    color: #000;
}

.conct_app {
    float: left;
    width: 100%;
    padding-left: 25%;
}

.conct_app span {
    color: #666;
}

@media screen and (max-width: 992px) {
    footer .subscribe {
        width: 100%;
    }

    footer .widget form .input-append .btn {
        display: block;
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}

@media screen and (max-width: 767px) {
    .conct_app {
        padding-left: 0;
    }

    .conct_app ul.link-list li i {
        font-size: 35px !important;
        width: auto;
        padding-right: 12px;
    }

    .conct_app h5,
    .conct_app ul.link-list li span {
        display: block;
    }

    .conct_app {
        width: 100%;
        text-align: center;
    }

    .conct_app ul li {
        display: inline-block;
    }

    footer h5.widgetheading {
        letter-spacing: normal;
    }

    .news-lt-mob {
        display: block;
    }

    .mo-aligncenter {
        text-align: center;
    }

    #sub-footer .copyright p,
    [class*="span"] {
        margin-bottom: 0px;
    }

    footer .subscribe {
        float: none !important;
        width: 100% !important;
        margin: 0 auto;
        max-width: 100%;
    }

    .subscribe .row {
        display: flex;
        flex-wrap: nowrap;
    }

    .subscribe .input-append2 {
        width: 60%;
    }

    .subscribe .subscribe-btn {
        /* width: 40%; */
        /* margin-left: 0px; */
    }

    /* .subscribe {
        display: none;
    } */

    .subscribe-1 {
        display: block;
    }

    .subscribe-1 .subscribe-btn {
        margin-right: 300px;
    }


    .abt-u,
    .cus-ser {
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }

    .topsearch-c {
        width: 100%;
    }
}

.btn.btn-theme.subscribe-btn:hover{
    color: #fff;
}