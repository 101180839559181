#inner-headline ul.breadcrumb {
    margin: 0;
    float: none;
    background: none;
    padding-left: 0px;
    text-align: left;
}

#inner-headline ul.breadcrumb li {
    margin-bottom: 0;
    padding-bottom: 0;
}

#inner-headline ul.breadcrumb li {
    font-size: 13px;
    color: #888;
    text-shadow: none;
}

#inner-headline ul.breadcrumb li i {
    color: #ababab;
}

#inner-headline ul.breadcrumb li a {
    text-decoration: none;
    color: #888;
}

#inner-headline ul.breadcrumb li a:hover {
    text-decoration: none;
    color: #aaa;
}

.breadcrumb>li+li:before {
    padding: 0 0px;
    color: #ccc;
    content: "/\00a0";
    text-align: center;
    margin: 0 0 0 5px;
}

#body_content {
    position: relative;
    background: #fff;
    padding: 30px 0 0 0;
}

#body_content img {
    max-width: 100%;
    height: -webkit-fill-available;
    transition: transform 1s;
    color: transparent;
    filter: brightness(.97);
}

.image-ratio {
    height: 270px;
}

#crossButton img {
    width: 16px !important;
    height: auto;
}

.filter-nav .navbar-nav>li>a {
    padding: 11px 20px;
}

.filter-main {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    margin-top: -30px;
    display: inline-block;
}

.filter-nav {
    color: #000;
    display: inline-block;
    vertical-align: top;
}

.filter-nav ul.nav li.dropdown a {
    color: #595959;
    background: #f8f8f8;
    font-size: 14px;
}

.filter-nav ul.nav li.dropdown a:hover {
    color: #595959;
    background: #efefef;
}

.filter-nav ul.nav li.dropdown.open a {
    color: #595959;
    background: #efefef;
}

.filter-nav .dropdown-menu {
    padding: 20px 25px;
    background: #ffffff;
}

.filter-nav .dropdown-menu ul li {
    list-style: none;
}

.filter-nav .dropdown-menu .filter-title.sbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
}

.filter-nav .dropdown-menu .filter-title {
    display: block;
    width: 100%;
    max-width: 100%;
    font-size: 15px;
    font-weight: bold;
    margin-top: 10px;
}

.filter-nav .dropdown-menu .filter-title .b_search {
    display: inline;
    margin-left: 0rem;
    width: 50%;
}

.filter-nav .dropdown-menu .filter-title .b_search input {
    border: solid 1px #e3e3e3;
    padding: 8px 15px;
    font-size: 13px;
    width: 100%;
    font-weight: normal;
}

.filter-nav .dropdown-menu .filter-title .b_search input:focus {
    outline: none;
    border: solid 1px #e3e3e3;
}

.filter-nav .dropdown-menu ul .term-item {
    max-width: 185px;
    /* min-width: 128px; */
    min-width: 20%;
    width: 100%;
    font-size: 13px;
}

.filter-nav .dropdown-menu ul .term-item .filter-options {
    position: absolute;
    top: 6px;
    left: 28px;
    font-weight: normal;
    white-space: nowrap;
    width: 150px;
    height: 20px;
    /* word-wrap: break-word; */
    /* word-break: break-all; */
    padding: 0px;
    line-height: 1;
}

.image-checkbox {
    cursor: pointer;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid transparent;
    margin-bottom: 0;
    outline: 0;
    padding: 3px;
    position: relative;
    padding-top: 4px;
}

.image-checkbox img {
    width: 18px;
    height: 18px !important;
}

.filter-nav li.dropdown.show .dropdown-menu .fx-ht {
    height: auto;
    max-height: 350px;
    overflow-y: scroll; /* Enable scrolling */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.filter-nav li.dropdown.show .dropdown-menu .fx-ht::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.filter-nav li.dropdown.show .dropdown-menu .fx-ht::-webkit-scrollbar {
    -webkit-appearance: none;
}

.filter-nav li.dropdown.show .dropdown-menu .fx-ht::-webkit-scrollbar:vertical {
    width: 11px;
}

.filter-nav li.dropdown.show .dropdown-menu .fx-ht::-webkit-scrollbar:horizontal {
    height: 11px;
}

.filter-nav li.dropdown.show .dropdown-menu .fx-ht::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid #efefef;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

#myFilter ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    list-style: none;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0px;
}

#myFilter ul.color {
    width: 70%;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-left: 0px;
    align-items: center;
}

#myFilter ul.vertical_clm {
    width: 33%;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-left: -3px;
}

#myFilter ul.horizontal_row {
    width: 100%;
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    /* margin-left: -3px; */
}

#myFilter ul.size {
    float: left;
    width: 150px;
    height: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    margin-left: -3px;
}


.mo-filter-sort {
    display: none;
    float: left;
    width: 100%;
    margin-bottom: 5px;
}

.mobile-filter-close {
    display: none;
    position: absolute;
    z-index: 999;
    right: 0px;
    top: -13px;
}

.filter_tag {
    float: left;
    width: 100%;
    margin-bottom: 5px;
}

.filter_tag ul {
    float: left;
    width: 100%;
    list-style: none;
}

.filter_tag ul li {
    float: left;
    margin-right: 5px;
    color: #595959;
    font-size: 12px;
    margin-bottom: 6px;
}

.filter_tag ul li p {
    display: inline-block;
    font-size: 12px;
    margin-left: 15px;
    color: #595959;
    margin-bottom: 0;
}

.filter_tag ul li a {
    padding: 4px 8px;
    display: inline-block;
    background-color: #f2f2f2;
    color: #000;
}

.filter_tag ul li a:hover {
    text-decoration: none;
    background: #f1f1f1;
    color: #000;
    text-decoration: none;
}

.filter_tag ul li a:focus {
    color: #000;
}

.filter_tag .clear-sel-filter {
    font-size: 11px;
    text-transform: uppercase;
    text-decoration: underline;
    color: #000;
}

.filter_tag .clear-sel-filter a {
    color: #000;
    text-decoration: underline;
}

.filter_tag .clear-sel-filter a:hover {
    text-decoration: underline;
    background: none;
}

.simple_label p {
    font-size: 16px !important;
    color: #121214;
}


.sort-nav {
    display: block;
    vertical-align: top;
    /* float: right; */
    /* new css on 21/09 */
    position: absolute;
    right: 0;
    top: 0;
}

.sort-nav .navbar-nav {
    /* float: none; */
}

.sort-nav .sort-fil-arw {
    /* margin-left: 14px; */
    font-size: 14px;
    color: #595959;
}

#mySort ul.nav li.dropdown a {
    color: #595959;
    background: #f8f8f8;
    padding: 7px 20px 7px 20px;
}

#mySort .nav>li.dropdown.open .dropdown-menu {
    width: 13%;
    right: 1% !important;
    left: auto;
    padding: 0rem;
}

#mySort .nav>li,
.sort-ul>li {
    list-style: none;
}

.sort-ul>li>a {
    background: transparent !important;
    padding: 0.8rem 1.2rem !important;
    display: block;
}

.sort-ul .term-item a.active {
    background: #f8f8f8 !important;
}

.sort-ul>li>a:hover {
    text-decoration: none;
    background: #f5f5f5 !important
}

.sort-ul {
    padding: 0px;
}

.navbar-nav {
    flex-direction: row !important;
}

.navbar .navbar-nav>li> {
    justify-content: space-between;
}

.navbar .navbar-nav>li>:first-child {
    padding: 11px 20px;
    /* padding: 7px 20px 1  4px; */
}


.left-sidebar {
    border-right: 1px solid #e9e9e9;
    padding: 0 20px 0 0;
    background-color: #fff;
    z-index: 999
}

.cat {
    list-style-type: none;
}

ul.cat li a,
ul.folio-detail li a {
    color: #656565;
    font-size: 13px;
    letter-spacing: 0.2px;
}

ul.cat li a.active {
    text-decoration: underline;
    color: #000000;
}

ul.cat li a:hover {
    text-decoration: underline;
    color: #000000;
}


.pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    padding: 1px 14px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #393939;
    text-decoration: none;
    background-color: #fff;
    border-radius: 100%;
    vertical-align: middle;
    line-height: 32px;
    text-align: center;
}

.pagination>li>a:hover,
.pagination>li>span:hover {
    color: #878787;
    cursor: default;
    background-color: #f8f8f8;
    border-color: #e3e3e3;
}


ul.pageview {
    margin: 0px;
    padding: 0px;
}

ul.pageview li {
    display: inline-block;
    padding: 0px;
    vertical-align: top;
}

ul.pageview li input.no-of-page {
    border: solid 1px #e3e3e3;
    width: 47px;
    text-align: left;
    padding: 6px 5px;
    margin-left: 5px;
    text-align: center;
}

ul.pageview li input.no-of-page:focus {
    border: solid 1px #e3e3e3;
    outline: none;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    z-index: 3;
    color: #393939;
    cursor: default;
    background-color: #e3e3e3;
    border-color: #e3e3e3;
}

.pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    padding: 5px 12px !important;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #393939;
    text-decoration: none;
    background-color: #fff;
    border-radius: 100%;
    vertical-align: middle;
    line-height: 32px;
    text-align: center;
}

.pagination>li>a,
.pagination>li>span {
    border: none;
    margin: 0 3px;
}

.pagination a {
    color: black;
    float: left;
    text-decoration: none;
    transition: background-color .3s;
}

.fa-angle-left:before {
    content: "\f104";
}

.fa-angle-right:before {
    content: "\f105";
}

pagination li:first-child a i,
.pagination li:last-child a i {
    font-size: 22px;
    padding-top: 5px;
}

.fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.product-cnt {
    padding: 0px;
    margin-bottom: 15px;
    position: relative;
}

.product-cnt strike {
    font-size: 100%;
    font-size: 12px;
}

.product-cnt small {
    font-size: 100%;
    font-size: 12px;
}

.product-cnt .prod-cashbck {
    color: #c7c5c5;
}

.product-cnt img {
    width: 100%;
    max-width: 100%;
}

.product-cnt .post-heading .stor_name {
    display: none;
    font-size: 16px;
    font-weight: normal;
    color: #c7c5c5;
    cursor: pointer;
}

.product-cnt .post-heading:hover .prod-cashbck {
    display: none;
}

.product-cnt .post-heading:hover .stor_name {
    display: block;
}

.pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    padding: 1px 14px;
    margin-left: -1px;
    line-height: 1.42857143;
    color: #393939;
    text-decoration: none;
    background-color: #fff;
    border-radius: 100%;
    vertical-align: middle;
    line-height: 32px;
    text-align: center;
}

.pagination>li>a:hover,
.pagination>li>span:hover {
    color: #878787;
    cursor: default;
    background-color: #f8f8f8;
    border-color: #e3e3e3;
}

.post-image .post-heading {
    font-size: 12px;
    margin-bottom: 5px;
}

.post-image .post-heading {
    font-size: 12px;
    margin-bottom: 5px;
}


.container-desp {
    width: 88%;
    margin: 10px 7PX 0px 0px;
    overflow: hidden;
}

.container-desp strike+small {
    color: #bb2222;
    font-weight: 500;
    margin-left: 5px;
    font-size: 12px;
}

.container-desp strike+small:hover {
    color: #bb2222;
}

.container-desp b {
    color: #000;
    font-size: 12px;
    letter-spacing: 1.1px;
}

.container-desp small {
    color: #5e5e5e;
}

.container-desp small:hover {
    color: #5e5e5e;
    text-decoration: underline;
    cursor: pointer;
}

.container-desp a {
    color: #5e5e5e;
    /* font-size: 12px; */
    font-weight: 500;
    letter-spacing: 0;
    text-transform: uppercase;
    font-family: 'helveticaneuemedium', Arial, Helvetica, sans-serif;
}

.filter-nav .navbar-nav>li.dropdown.show .dropdown-menu {
    z-index: 9999;
    position: absolute;
    transform: translate3d(2px, -9px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;
    display: table;
    width: 100%;
    right: 0;
    top: 42px;
    /* box-shadow: 0px 0px 1px 0px rgb(175 175 175 / 50%); */
    box-shadow: 8px 3px 4px 3px rgb(175 175 175 / 50%);
    border: 0;
}

.sort-nav .navbar-nav>li.dropdown.show .dropdown-menu {
    position: relative;
    width: auto;
    top: 0;

}

.side-navbar {
    display: flex;
    flex-wrap: wrap;
    margin-right: 11px !important;
    margin-left: 15% !important;
}
.row .product-main {
    margin-left: -206px;
    gap: 0px
}

#myFilter ul {
    display: flex;
    width: 100%;
    align-items: flex-start;
    list-style: none;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0px;
}

.searchAlpha li {
    display: flex;
    color: #ccc;
    padding: 0 7px;
    font-weight: normal;
    cursor: pointer;
}

.searchAlpha li:hover {
    color: #737373;
}

.searchAlpha-dark {
    color: #737373 !important;
}

.page-item.active .page-link {
    z-index: 3;
    color: #393939 !important;
    cursor: default;
    background-color: #e3e3e3 !important;
    border-color: #e3e3e3;
    padding: 5px 12px;
}

.checkbx .image-checkbox-checked {
    border-color: #fff !important;
}

.checkbx .image-checkbox-checked::after {
    content: "";
    width: 12px;
    height: 12px;
    background: #000;
    position: absolute;
    top: 8px;
    left: 6px;
    z-index: -1;
}

.color-hovered:hover {
    border: 1px solid #271717;
}


.color-hovered {
    border: .3px solid #271717;
}

.productImageContainer {
    width: 270px;
    height: 400px;
    overflow: hidden;
    background-color: #eeeeee;
}

.productImageContainer img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.fa-heart {
    color: #bb2222 !important;
}

.container-desp .my_whish_btn:hover {
    color: #bb2222 !important;
}


@media screen and (max-width: 600px) {
    .filter .nav .navbar-nav {
        width: 10px;
        height: 10px;
    }

    .filter-nav ul.nav li.dropdown a {
        color: #595959;
        background: #f8f8f8;
        font-size: 11px;
    }

    #myFilter ul {
        flex-wrap: nowrap;
    }

    .productImageContainer {
        height: 250px;
        width: 180px;
    }

    .row .row {
        margin-left: 0;
    }

    .row .product-main {
        width: 100vw;
        margin-top: 55px;
        margin-left: -118px;
        display: flex;
        justify-content: space-between;
    }

    .DataTables_Table_0_paginate {
        margin-left: 70px;
    }

    #inner-headline ul.breadcrumb li {
        font-size: 11px;
    }

    /* #myFilter{
        display: none;
    } */

    .sort-nav.nav .navbar-nav {
        /* margin-top: 30px; */
    }

    .sort-nav .dropdown {
        /* margin-left: 100px; */
        /* margin-top: 40px; */
    }

    .sort-nav {
        top: 35px;
        left: -20px;
        /* left: 0; */
        z-index: 100;
        /* word-break: break-all; */
        /* word-wrap: break-word; */
        /* margin-bottom: 100px; */
    }

    #mySort ul.nav li.dropdown a {
        display: block;
        /* width: 130px; */
        /* padding: 7px 10px; */
    }

    .pagination {
        /* border: 1px solid black; */
        margin-top: 50px;
        /* margin-bottom: 100px; */
    }

    .pagination li {
        /* display: block; */
    }

    .filter-nav .navbar-nav>li.dropdown.show .dropdown-menu {
        /* overflow: hidden; */
        margin-left: -10px;
        /* width: 100px; */
        height: 100px;
        /* background-color: #000000; */
    }
    .sort-nav .navbar-nav>li.dropdown.show .dropdown-menu{
        top: 40px;
    }
    .filter-nav li.dropdown.show .dropdown-menu .fx-ht {
        width: 250px;
        /* height: 135px; */
    }

    .filter-nav .dropdown-menu .filter-title.sbox {
        display: block;
    }

    .filter-nav .dropdown-menu .filter-title.sbox .searchAlpha {
        /* flex-wrap: wrap; */
    }

    .sort-nav .navbar-nav>li.dropdown.show .dropdown-menu {
        /* right: 900px; */
    }

    .image-checkbox img{
        margin-top: -7px;
    }
}

@media screen and (max-width: 400px) {
    .filter-nav .navbar-nav .dropdown-menu .filter-title.sbox .searchAlpha {
        margin-left: -3px;
    }

    .dropdown-menu {
        overflow: hidden;
        padding: 0px;
    }

    #myFilter ul.horizontal_row {
        margin: 0px;
        overflow-x: hidden;
    }

    .filter-nav .navbar-nav .dropdown-menu .filter-title.sbox .searchAlpha.brand {
        margin-left: 0px;
    }
}