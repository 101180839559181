body{
    font-family: 'BrownLight';
}
.product-img {
    padding-top: 20px;
}

.product-content-1 {
    margin: 20px;
    width: 41.6667%;
    padding: 10px;
    text-align: center;
    background-color: rgb(255, 255, 255);
    position: absolute;
    right: 0;
    top: 150px;
}

.about-font {
    font-size: 14px;
    line-height: normal;
}

.about-span-text {
    font-size: 18px;
    font-family: 'BrownLight';
}

.about-url {
    line-height: 5;
    font-size: 14px;
}

.about-heading {
    /* line-height: 2; */
    font-size: 35px;
}

.product-content-2 {
    margin: 20px;
    width: 41.6667%;;
    padding: 10px;
    text-align: center;
    background-color: rgb(255, 255, 255);
    position: absolute;
    left: 0;
    top: 50px;
}
.content-2-line{
    font-size: 35px;
    font-family: 'BrownLight';
}
.h4-font {
    font-family: 'BrownLight';
    font-size: 20px;
    color: rgb(49, 48, 48);
    box-sizing: inherit;
    line-height: 1.4;

}

.product-content-3 {
    margin: 20px;
    width: 41.6667%;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    right: 0;
    top: 100px;
}

.about-section {
    position: relative;
}

.mb-50 {
    margin-bottom: 50px;
}

.ml-200 {
    margin-left: 200px;
    font-size: 14px;
}

.pt-50 {
    padding-top: 50px;
}

.align-center {
    text-align: center;
    font-size: 14px;
}

.h3-font {

    font-family: 'BrownLight';
    font-size: 33px;
    color: rgb(49, 48, 48);
}

.line-ht {
    line-height: 1.4;
}

.center {
    text-align: center;
}

.about-last-paragraph {
    font-size: 17px;
    box-sizing: inherit;
    text-align: center;
}


/* Media Query for Tablets Ipads portrait mode */

@media only screen and (width <=820px) {
    .about-section {
        width: 100%;
    }

    .product-img {
        width: 100%;
    }

    .product-content-1 {
        top: 75px;
    }

    .product-content-2 {
        top: 70px;
    }

    .product-content-3 {
        top: 75px;
    }

    .about-page-ht {
        height: auto;
    }
    .about-heading {
        line-height: unset;
    }

}


/* Media Query for Mobile Devices */

@media only screen and (width <=480px) {
    .about-sectio {
        width: 100%;
    }

    .product-img {
        width: 100%;
    }

    .product-content-1 {
        top: 75px;
        margin: unset;
        width: 100%;
        position: unset;
        margin-bottom: -60px;
    }

    .product-content-2 {
        top: 70px;
        width: 100%;
        margin: unset;
        position: unset;
        margin-bottom: -40px;
    }

    .product-content-3 {
        top: 75px;
        width: 100%;
        margin: unset;
        margin-top: 117px;
    }
    .mb-phone{
        margin-bottom: 335px;
    }

    .about-page-ht {
        height: auto;
    }

    .about-img {
        width: 100%;
        margin-left: 85px;
    }

    .ml-85 {
        margin-left: -85px;
    }

    .about-last-paragraph {
        font-size: 13px;
    }
    .ml-200 {
        margin-left: 125px
    }
    .h4-font{
        font-size: 33px;
    }
}