.quick-vmodal {
    z-index: 999999999;
}

.quick-vmodal .modal-dialog {
    margin: 4% auto 0% auto;
    max-width: 900px;
}

.quick-vmodal .modal-content {
    border-radius: 0;
}
#overlay {
    border: 1px solid black;
    width: 200px;
    height: 200px;
    display: inline-block;
    background-image: url('https://via.placeholder.com/400.png');
    background-repeat: no-repeat;
  }

.quick-vmodal .modal-content .button.close {
    margin-right: 0px;
    font-size: 18px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

.quick-vmodal .modal-content button.close:hover {
    opacity: 1;
}

.quick-vmodal .simpleLens-container {
    float: right;
    width: 71%;
}

.quick-vmodal .modal-content .aa-product-view-slider .simpleLens-container .simpleLens-big-image-container {
    width: 100%;
}

.quick-vmodal .modal-content .aa-product-view-slider .simpleLens-container .simpleLens-big-image-container .simpleLens-lens-image {
    width: 100%;
}

.quick-vmodal .simpleLens-thumbnails-container {
    text-align: center;
    float: left;
    width: 28%;
}

.quick-vmodal .modal-content .aa-product-view-content {
    float: left;
    width: 100%;
    padding-right: 10px;
    padding-bottom: 10px;
}

.quick-vmodal .aa-product-view-content h3 {
    margin-top: 0px;
    margin-bottom: 5px;
    text-transform: uppercase;
    color: #000;
    font-weight: 600;
    font-size: 20px;
}

.quick-vmodal .aa-prod-view-size h4 {
    display: inline-block;
    margin-right: 5px;
    width: 33px;
}

.scrollbar-black::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #F5F5F5;
    border-radius: 10px;
}

.scrollbar-black::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}

.scrollbar-black::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #000;
}

.quick-vmodal .quick-desc {
    float: left;
    width: 100%;
    max-height: 215px;
    overflow-y: scroll;
}

.quick-vmodal .quick-desc h4 {
    color: #000;
    display: inline-block;
    margin-right: 5px;
    font-size: 14px;
    font-weight: bold;
}

.quick-vmodal .quick-desc p {
    float: left;
    width: 100%;
    font-size: 13px;
    margin-bottom: 10px;
    color: #000;
}

.quick-vmodal .quick-desc ul {
    float: left;
    width: 100%;
    font-size: 14px;
    margin-bottom: 10px;
    padding-left: 17px;
}

.quick-vmodal .quick-desc ul li {
    list-style: disc;
    line-height: 26px;
}

.quick-vmodal a.quick_full_desc {
    float: left;
    width: 100%;
    font-size: 15px;
    text-decoration: underline;
    text-transform: uppercase;
    margin-bottom: 15px;
}

.quick-vmodal a.quick_full_desc:hover {
    color: #000;
}

.quick-vmodal .aa-product-view-price {
    font-size: 14px;
    font-weight: bold;
    color: #595959;
}

.quick-vmodal .aa-price-block {
    float: left;
    width: 100%;
    margin-bottom: 10px;
}

.quick-vmodal .aa-price-block h4 {
    display: inline-block;
    margin-right: 5px;
    min-width: 43px;
    font-size: 14px;
    font-weight: bold;
    color: #000;
}

.quick-vmodal .aa-prod-category {
    display: block;
    float: left;
}

.quick-vmodal .aa-product-avilability {
    display: block;
    float: right;
}

.quick-vmodal .ad_option {
    float: left;
    width: 100%;
    border-top: 1px solid #bfbfbf;
    padding-top: 15px;
}

.quick-vmodal .aa-prod-view-size {
    float: left;
    width: 100%;
    margin-bottom: 10px;
}

.quick-vmodal .aa-prod-view-color {
    float: left;
    width: 100%;
    margin-bottom: 15px;
}

.quick-vmodal .aa-prod-view-size h4 {
    display: inline-block;
    margin-right: 5px;
    min-width: 43px;
    font-size: 14px;
    font-weight: bold;
    color: #000;
}

.quick-vmodal .aa-prod-view-size a {
    border: 1px solid #8e8e8e;
    display: inline-block;
    font-size: 13px;
    letter-spacing: 0.5px;
    margin-bottom: 5px;
    margin-right: 8px;
    padding: 3px 10px;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    min-width: 24px;
    text-align: center;
    font-weight: 400;
    color: #000!important;
}

.quick-vmodal .aa-prod-view-size a:hover,
.quick-vmodal .aa-prod-view-size a:focus {
    border-color: #656565;
    color: #ffffff!important;
    text-decoration: none;
    background: #656565;
}

.quick-vmodal .aa-prod-view-size a.siz-diseble {
    border: 1px dashed #959595;
    color: #959595!important;
    cursor: default;
}

.quick-vmodal .aa-prod-view-size a.siz-diseble:hover {
    border: 1px dashed #959595;
    color: #959595!important;
    background: none;
}

.quick-vmodal .aa-prod-view-color .dropdown-colorselector {
    display: inline-block;
}

.quick-vmodal .aa-prod-view-color h4 {
    display: inline-block;
    margin-right: 5px;
    min-width: 43px;
    font-size: 14px;
    font-weight: bold;
    color: #000;
}

.quick-vmodal .modal-content .aa-product-view-content .aa-prod-view-color a {
    display: inline-block;
    font-size: 13px;
    margin-left: 0px;
    padding: 0px;
}

.quick-vmodal .aa-add-to-cart-btn:hover,
.quick-vmodal .aa-add-to-cart-btn:focus {
    border-color: #000;
    color: #ffffff;
    text-decoration: none;
    background: #2d2d2d;
}

.quick-vmodal .aa-prod-quantity {
    float: left;
    width: 100%;
    margin-bottom: 15px;
}

.quick-vmodal .aa-prod-quantity h4 {
    display: inline-block;
    margin-right: 5px;
    width: 33px;
    font-size: 14px;
    font-weight: bold;
    color: #000;
    min-width: 43px;
}
.number-input {
    border: 1px solid #ddd;
    display: inline-flex;
}
.number-input button {
    outline: none;
    -webkit-appearance: none;
    background-color: #2f2f2f;
    border: none;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    margin: 0;
    position: relative;
}
.number-input input[type=number] {
    max-width: 5rem;
    padding: 0.5rem;
    border: solid #ddd;
    border-width: 0 1px;
    font-size: 15px;
    height: 2rem;
    font-weight: 700;
    text-align: center;
}
.quick-vmodal .modal-content .aa-product-view-content .aa-prod-quantity form {
    display: inline-block;
}

.quick-vmodal .modal-content .aa-product-view-content .aa-prod-quantity form select {
    height: 25px;
    width: 40px;
}

.quick-vmodal .aa-prod-category {
    display: inline-block;
}

.quick-vmodal .aa-prod-category a {
    font-size: 16px;
}

.quick-vmodal .aa-prod-category a,
.quick-vmodal .aa-product-avilability span {
    color: #656565;
}
.quick-vmodal .aa-product-avilability #inStock {
    color: green;
}
.quick-vmodal .aa-product-avilability #outOfStock {
    color: red;
}

.quick-vmodal .aa-prod-view-bottom {
    float: left;
    width: 100%;
    padding-top: 10px;
    letter-spacing: 1px;
}


.no-margin {
    margin: 0px!important;
}

.no-padding {
    padding: 0px!important;
}

.quick-vmodal .aa-add-to-cart-btn {
    border: 1px solid #000;
    color: #fff;
    text-transform: uppercase;
    background: #000;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    padding: 12px 15px;
    transition: all 0.5s ease 0s;
    text-align: center;
}

.quick-vmodal .aa-add-to-fav-btn {
    text-transform: uppercase;
    color: #000;
    text-decoration: underline;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    padding: 12px 15px;
    transition: all 0.5s ease 0s;
    text-align: center;
}

.quick-vmodal .aa-add-to-fav-btn:hover {
    color: #595959;
}

.quick-vmodal .bor-top {
    border-top: solid 1px #bfbfbf;
    margin-top: 7px;
}


/* .simpleLens-big-image-container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    position: relative;
    height: 300px;
    width: 350px;
} */


.quick-vmodal .simpleLens-thumbnails-container {
    text-align: center;
    float: left;
    width: 28%;
}
/* .simpleLens-thumbnails-container a {
    display: inline-block;
    width: 100%;
    height: 71px;
    overflow: hidden;
} */