@font-face {
    font-family: 'Brown';
    src: url('BrownBoldItalic.eot');
    src: url('BrownBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('BrownBoldItalic.woff2') format('woff2'),
        url('BrownBoldItalic.woff') format('woff'),
        url('BrownBoldItalic.ttf') format('truetype'),
        url('BrownBoldItalic.svg#BrownBoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Brown';
    src: url('BrownBold.eot');
    src: url('BrownBold.eot?#iefix') format('embedded-opentype'),
        url('BrownBold.woff2') format('woff2'),
        url('BrownBold.woff') format('woff'),
        url('BrownBold.ttf') format('truetype'),
        url('BrownBold.svg#BrownBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Brown';
    src: url('BrownItalic.eot');
    src: url('BrownItalic.eot?#iefix') format('embedded-opentype'),
        url('BrownItalic.woff2') format('woff2'),
        url('BrownItalic.woff') format('woff'),
        url('BrownItalic.ttf') format('truetype'),
        url('BrownItalic.svg#BrownItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'BrownLight';
    src: url('BrownLightItalic.eot');
    src: url('BrownLightItalic.eot?#iefix') format('embedded-opentype'),
        url('BrownLightItalic.woff2') format('woff2'),
        url('BrownLightItalic.woff') format('woff'),
        url('BrownLightItalic.ttf') format('truetype'),
        url('BrownLightItalic.svg#BrownLightItalic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'BrownExtraBoldCondensed';
    src: url('BrownExtraBoldCondensed.eot');
    src: url('BrownExtraBoldCondensed.eot?#iefix') format('embedded-opentype'),
        url('BrownExtraBoldCondensed.woff2') format('woff2'),
        url('BrownExtraBoldCondensed.woff') format('woff'),
        url('BrownExtraBoldCondensed.ttf') format('truetype'),
        url('BrownExtraBoldCondensed.svg#BrownExtraBoldCondensed') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BrownMedium';
    src: url('BrownMediumItalic.eot');
    src: url('BrownMediumItalic.eot?#iefix') format('embedded-opentype'),
        url('BrownMediumItalic.woff2') format('woff2'),
        url('BrownMediumItalic.woff') format('woff'),
        url('BrownMediumItalic.ttf') format('truetype'),
        url('BrownMediumItalic.svg#BrownMediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'BrownMedium';
    src: url('BrownMedium.eot');
    src: url('BrownMedium.eot?#iefix') format('embedded-opentype'),
        url('BrownMedium.woff2') format('woff2'),
        url('BrownMedium.woff') format('woff'),
        url('BrownMedium.ttf') format('truetype'),
        url('BrownMedium.svg#BrownMedium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BrownHeavy';
    src: url('BrownHeavy.eot');
    src: url('BrownHeavy.eot?#iefix') format('embedded-opentype'),
        url('BrownHeavy.woff2') format('woff2'),
        url('BrownHeavy.woff') format('woff'),
        url('BrownHeavy.ttf') format('truetype'),
        url('BrownHeavy.svg#BrownHeavy') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BrownLight';
    src: url('BrownLight.eot');
    src: url('BrownLight.eot?#iefix') format('embedded-opentype'),
        url('BrownLight.woff2') format('woff2'),
        url('BrownLight.woff') format('woff'),
        url('BrownLight.ttf') format('truetype'),
        url('BrownLight.svg#BrownLight') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'BrownExtraBold';
    src: url('BrownExtraBold.eot');
    src: url('BrownExtraBold.eot?#iefix') format('embedded-opentype'),
        url('BrownExtraBold.woff2') format('woff2'),
        url('BrownExtraBold.woff') format('woff'),
        url('BrownExtraBold.ttf') format('truetype'),
        url('BrownExtraBold.svg#BrownExtraBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

