 /* Login Register part */
 .nav-tabs {
     border-bottom: none !important;
     margin-bottom: 35px !important;
 }

 .nav-tabs .nav-link.active,
 .nav-tabs .nav-item.show .nav-link {
     border-bottom: 2px solid #000;
     font-weight: bold;
     color: #fff !important;
     background-color: #000 !important;
 }

 .nav-tabs .nav-link {
     font-weight: lighter;
     background: #dbdbdb;
     border-radius: 0;
     color: #000;
     padding: 10px 84px;
 }

 .nav-tabs .nav-link {
     border-radius: 0 !important;
     font-size: 18px;
 }


 /* spacing down the whole section */

 #login_register {
     margin: 120px 0px;
 }


 /* Turning off rounded box */

 #login_register input,
 #login_register a.btn,
 #login_register button {
     -webkit-border-radius: 0 !important;
     -moz-border-radius: 0 !important;
     -ms-border-radius: 0 !important;
     -o-border-radius: 0 !important;
     border-radius: 0 !important;
     outline: 0 !important;
 }


 /* space in the bottom after both login and register column */

 .login,
 .register {
     padding-bottom: 25px;
 }


 /* heading styles */

 #login_register h3 {
     font-family: 'Lato', sans-serif;
     font-size: 27px;
     color: #000;
     font-weight: 400;
     margin-bottom: 47px;
     text-align: center;
     margin-left: -25px;
 }


 /* styles for login div */

 .login {
     padding: 0 88px 0 122px;
 }

 .register {
     padding-left: 90px;
     padding-right: 150px;
     border-left: 1px solid #ababab;
     margin-left: -30px;
 }


 /* altering the defaults for col-row from bootstrap */

 .form-row>.col,
 .form-row>[class*="col-"] {
     padding-right: 5px;
     padding-left: 0px;
     margin-bottom: 0px;
 }


 /* altering the defaults for col-row last-child from bootstrap */

 .form-row>.col,
 .form-row>[class*="col-"]:last-child {
     padding-right: 0px;
 }


 /* Styles for labels */

 label {
     font-size: 13px;
     color: #777;
     font-weight: 400;
     display: inline;
 }


 /* Styles for forgot password part */

 .reset_pass {
     position: relative;
     float: right;
     /* margin-top: 10px; */
 }


 /* Styles for forgot password anchor tag */

 .reset_pass a {
     font-size: 14px;
     color: #000;
     font-weight: 400;
     padding-bottom: 2px;
     -webkit-transition: all linear .3s;
     -moz-transition: all linear .3s;
     -ms-transition: all linear .3s;
     -o-transition: all linear .3s;
     transition: all linear .3s;
     border-bottom: 1px solid #000;
 }


 /* Styles for input boxes*/

 .reg-form {
     height: 38px !important;
     font-size: 14px !important;
     margin-bottom: 18px;
 }


 /* Styles for specific registration part input boxes*/


 /* Styles for check boxes */
 /* 
    input[type='checkbox'] {
        width: 15px;
        height: 16px;
        padding: 0;
        margin: 0;
        margin-top: 12px !important;
        vertical-align: text-bottom;
        position: relative;
        top: -1px;
        overflow: hidden;
    } */


 /* Styles for check box div */

 /* .form-check {
        margin-top: -20px;
        padding: 0;
    } */


 /* Styles for specific labels of check boxes */

 /* label.check {
        font-size: 14px;
        font-weight: 400;
        margin-left: 3px;
        margin-top: -2px;
    } */


 /* Styles for al the buttons and links contains .btn class */

 .btn {
     font-size: 16px;
     font-weight: 400;
     height: 38px;
     text-transform: uppercase;
     border-radius: 0 !important;
 }


 /* Styles for Sign in button */

 button.signin {
     margin: 25px 0px;
     background: #000;
     line-height: 25px;
     color: #fff;
     letter-spacing: 1.5px;
     border-radius: 0;
     -webkit-transition: all linear .3s;
     -moz-transition: all linear .3s;
     -ms-transition: all linear .3s;
     -o-transition: all linear .3s;
     transition: all linear .3s;
 }


 /* Styles for Sign in button on hover */

 button.signin:hover {
     color: #fff;
     background: #1d1d1d;
 }


 /* Styles for Social login buttons */

 a.social_log {
     margin: 18px 0 6px 0px;
 }


 /* button and links specific styles */

 a.btn,
 button {
     width: 100%;
     line-height: 26px;
 }


 /* styles for login/register with facebook button*/

 a.facebook {
     background: #3b5998;
     -webkit-transition: all linear .3s;
     -moz-transition: all linear .3s;
     -ms-transition: all linear .3s;
     -o-transition: all linear .3s;
     transition: all linear .3s;
 }


 /* styles for login/register with facebook button on hover*/

 a.facebook:hover {
     background: #4161a5;
 }


 /* styles for facebook icon on button */

 a.facebook i {
     font-size: 24px;
 }


 /* styles for login/register with google button*/

 a.google {
     border: 1px solid #000;
     color: #000;
     -webkit-transition: all linear .3s;
     -moz-transition: all linear .3s;
     -ms-transition: all linear .3s;
     -o-transition: all linear .3s;
     transition: all linear .3s;
 }


 /* styles for login/register with google button on hover*/

 a.google:hover {
     background: #f1f1f1;
 }


 /* styles for google icon */

 a.google img {
     width: 17%;
 }


 /* styles for placeholder on the input boxes */

 ::placeholder {
     color: #ababab;
     font-weight: 400;
     font-size: 14px;
 }


 /* styles for "or" between buttons */

 p.middle {
     text-align: center;
     font-size: 16px;
     color: #000;
     font-weight: 400;
     text-transform: lowercase;
 }


 /* styles for "or" between buttons on registration part */

 .register p.middle {
     margin: 13px 0;
 }


 /* Styles for terms and conditoon agreement text */

 p.agree {
     color: #000;
     font-size: 14px;
     font-weight: 400;
 }


 /* styles for links on the terms and conditoon agreement text */

 a.terms {
     font-size: 13px;
     color: #000;
     border-bottom: 1px solid #000;
     -webkit-transition: all linear .3s;
     -moz-transition: all linear .3s;
     -ms-transition: all linear .3s;
     -o-transition: all linear .3s;
     transition: all linear .3s;
 }


 /* styles for links on the terms and conditoon agreement
    text and forgot password on hover */
 .back-link a {
     color: #0922dc;
     text-decoration: none;
 }

 .back-link a:hover {
     text-decoration: underline;
 }

 .reset_pass a:hover,
 a.terms:hover {
     color: #0922dc !important;
     border-color: #0922dc !important;
     text-decoration: none;
 }

 .reset-psd h3 {
     color: #010101;
     margin-bottom: 30px;
 }

 .reset-psd .form-group {
     margin-bottom: 20px;
     margin-top: 10px;
 }

 .reset-psd input {
     border-radius: 0;
     margin-bottom: 0;
     margin-top: 7px;
 }

 .reset-psd .invalid-feedback {
     font-size: 12px;
     letter-spacing: 1px;
     margin-bottom: 2px;
 }

 .reset-psd .input-group-addon {
     position: absolute;
     top: 10px;
     right: 27px;
 }

 .input-group-addon {
     position: absolute;
     top: 8px;
     right: 27px;
     font-size: 12px;
     color: #444;
 }


 .bottom-header-form-check label {
     font-size: 14px;
     font-weight: 400;
     color: black;
     top: 0;

     position: absolute;
 }

 /* floating input field css  */

 .custom-input-floating {
     display: block;
     width: 100%;
     height: 36px;
     padding: 0 20px;
     background: #fff;
     color: #000;
     border: 1px solid #d3d3d3;
     border-radius: 4px;
     box-sizing: border-box;
     font-size: 14px;
 }

 .custom-text-floating {
     position: absolute;
     pointer-events: none;
     left: 15px;
     top: 8px;
     padding: 0 5px;
     background: #fff;
     transition: 0.2s ease all;
     -moz-transition: 0.2s ease all;
     -webkit-transition: 0.2s ease all;
 }

 .custom-input-floating:focus~.custom-text-floating {
     top: -25% !important;
     /* transform: scale(0.8); */
     font-size: 12px;
     color: #444;
 }

 .custom-input-floating:not(:placeholder-shown)~.custom-text-floating {
     top: -25% !important;
     /* transform: scale(0.8); */
     font-size: 12px;
     color: #444;
 }

 .custom-text-floating .fname {
     position: absolute;
     pointer-events: none;
     left: 10px;
     top: 11px !important;
     padding: 0 5px;
     background: #fff;
     transition: 0.2s ease all;
     -moz-transition: 0.2s ease all;
     -webkit-transition: 0.2s ease all;
 }

 .show-text {
     color: #0922dc;
     font-size: 10px;
 }

 .box-check {
     display: block;
     position: relative;
     padding-left: 20px;
     font-weight: 300;
     cursor: pointer;
     font-size: 16px;
     -webkit-user-select: none;
     -moz-user-select: none;
     -ms-user-select: none;
     user-select: none;
 }

 .form-check {
     padding: 0;
 }

 /* Hide the browser's default checkbox */

 .box-check input {
     position: absolute;
     opacity: 0;
     cursor: pointer;
     height: 0;
     width: 0;
 }


 /* Create a custom checkbox */

 .checkmark {
     position: absolute;
     top: 4px;
     left: 0;
     height: 15px;
     width: 15px;
     background-color: #eee;
     border: 1px solid #000;
 }


 /* On mouse-over, add a grey background color */

 .box-check:hover input~.checkmark {
     background-color: #ccc;
 }


 /* When the checkbox is checked, add a black background */

 .box-check input:checked~.checkmark {
     /* background-color: #000; */
     background-color: #fff;
     border: 1px solid #000;
 }


 /* Create the checkmark/indicator (hidden when not checked) */

 .checkmark:after {
     content: "";
     position: absolute;
     display: none;
 }


 /* Show the checkmark when checked */

 .box-check input:checked~.checkmark:after {
     display: block;
 }


 /* Style the checkmark/indicator */

 .box-check .checkmark:after {
     left: 4px;
     top: 1px;
     width: 5px;
     height: 9px;
     border: solid #000;
     border-width: 0 2px 2px 0;
     -webkit-transform: rotate(45deg);
     -ms-transform: rotate(45deg);
     transform: rotate(45deg);
 }

 .spinner-border-sm {
     display: inline-block;
     margin-bottom: 1px;
     margin-right: 5px;
 }

 textarea.custom-input-floating {
     overflow-y: scroll;
     resize: none;
     height: 150px;
     border-radius: 0;
     padding-top: 7px;
 }

 .textareaLabel label.custom-text-floating.fname {
     top: -9% !important;
 }

 .success-icon svg {
     width: 150px;
     height: 150px;
     display: block;
     margin: 0 auto;
     color: #fff;
     margin-bottom: 20px;
 }

 .success-box {
     text-align: center;
 }

 /* toste css */
 button.Toastify__close-button,
 .Toastify__close-button {
     right: 9px;
     position: absolute;
     display: flex;
     justify-content: end;
 }

 .Toastify__toast-body {
     display: flex;
     width: 100%;
 }

 .custom-alert-css {
     padding: 7px !important;
     border-radius: 0 !important;
     font-size: 12px;
 }

 .alert-dismissible .close {
     top: -5px !important;
     font-size: 18px;
 }


 @media (max-width: 992px) {
     .register {
         padding: 0;
         border-left: none;
         margin-left: 0;
     }
 }

 @media (max-width: 767px) {
     #login_register {
         margin: 60px 0;
     }

     #sub-footer span {
         margin: 0 auto;
         display: table;
     }

     .navbar {
         padding: .5rem 0 !important;
     }
 }

 @media (max-width: 470px) {

     #login_register {
         margin: 30px 0;
     }

     .nav-tabs .nav-link {
         font-size: 16px;
         padding: 7px 60px;
     }

     #login_register h3 {
         font-size: 22px;
         margin: 0 0 30px;
     }

     .reset_pass a {
         font-size: 14px;
     }

     .bottom-header-form-check label {
         font-size: 14px;
         letter-spacing: 0;
     }

     .conct_app .link-list {
         display: flex;
         justify-content: space-evenly;
     }

     ul.link-list li span {
         font-size: 12px;
     }

     .conct_app ul.link-list li i {
         font-size: 24px !important;
     }

     .nav {
         flex-wrap: nowrap
     }

     .nav .nav-tabs {
         flex-wrap: nowrap;
     }

     .nav .nav-tabs .nav-item .nav-link{
         border: none;
     }

 }


 @media (max-width: 400px) {
     .nav .nav-tabs {
         flex-wrap: nowrap
     }

     .nav-tabs .nav-link {
         font-size: 12px;
         padding: 10px 40px;
     }

     .reset_pass a {
         font-size: 12px;
     }

     .bottom-header-form-check label {
         font-size: 12px;
         letter-spacing: 0;
     }

     .reset-psd h3 {
         font-size: 22px;
         margin-bottom: 10px;
     }

     .reset-psd p {
         font-size: 12px;
         margin-bottom: 10px;
     }

     .btn {
         font-size: 12px !important;
     }

     .success-box h5,
     .success-box h3 {
         font-size: 16px;
     }

     .subscribe .input-append2 .btn-theme {
         width: 50%;
     }
 }

 .login-alreay-have-account-text {
     margin-top: 14px;
 }