
.h5-heading {
    font-size: 17px;
    letter-spacing: 2px;
    margin-top: 15px;

}
.table-head {
    margin-top: 25px;
}
.pt-27 {
    padding-top: 27px;
}

.bold-font {
    font-size: 16px;
    margin-left: 3px;
}

.paragraph {
    font-size: 14px;
    color: black;
    margin-left: 3px;
}

.bold {
    font: weight 900px;
    ;
}

.line-ht {
    line-height: 0px;
}

.pt-40 {
    padding-top: 40px;
}

.seach-button {
    width: 250px;
    height: 25px;
    /* margin-left: -3px; */
    font-size: 16px;
    border: 1px solid #ddd;
}

.search-icons {
    width: 25px;
    height: 25px;
    position: absolute;
    margin-left: -30px;
    /* margin-top: 3px; */
}

.table-data {
    height: 35px;
    width: 35px;
    text-align: center;
    border: 1px solid #c2bebe;
    border-collapse: collapse;
    font-size: 13px;
    font-weight: bold;
}

.brand-table {
    border: 1px solid #c2bebe;
    height: 450px;
    width: 350px;
    margin-top: 25px;
}

.brand-table-font {
    font-size: 18px;
    padding: 18px;
    font-family: 'BrownLight';
}

label {
    font-size: 12px;
    margin-left: 5px;
}

input {
    border-radius: none;
}

.nav-pills .nav-link.active{
    color: #fff !important;
    background-color: #8b8786 !important;
    width: max-content;
    margin-top: 10px;
}
.text-uppercase{
    padding: 5px 8px !important;
}
.ml-3px{
    margin-left: 3px !important;
}
.mt-30{
    margin-top: -30px;
}
.ml-90{
    margin-left: 155px;
}

@media screen and (max-width: 767px) {
    .ml-90{
        margin-left: 0px;
    }
}
