body {
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.6em;
    color: #656565;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

a {
    color: #696969;
}

.btn:active,
.btn:focus,
input:focus,
a:focus,
a:visited,
a:active {
    outline: 0;
    text-decoration: none;
}

.clear {
    clear: both;
}

h2.heading {
    font-size: 32px;
    color: #0e161d;
    letter-spacing: -2px;
}

h2.errortitle {
    font-size: 64px;
    color: #0e161d;
    letter-spacing: -2px;
}

.bold {
    font-weight: 900;
}

h4.title {
    letter-spacing: -1.5px;
}

.container {
    position: relative;
}

#wrapper {
    width: 100%;
    margin: 0;
    padding: 0;
}

#wrapper.boxed {
    width: 1230px;
    overflow: hidden;
    margin: 0 auto;
    padding: 0;
    background: #fff;
}

.row {
    margin-bottom: 0px;
}

.row .row {
    margin-bottom: 30px;
}

.img-box {
    max-width: 100%;
}

header .login-register a {
    color: #000;
    font-size: 12px;
    padding: 7px 8px 25px 2px;
}

header .login-register a.dropdown-item {
    font-size: 12px;
}

.curncy .dropdown-menu a.dropdown-item {
    font-size: 12px;
}

header .login-register {
    float: right;
    position: relative;
    z-index: 2;
    right: 7px;
}

header .login-register .heart {
    border: 1px solid #ff0000;
}

header .logo img {
    margin-bottom: 10px;
}

header .logo h1 {
    color: #000000;
    letter-spacing: -1px;
    text-transform: uppercase;
    font-size: 2.4em;
    letter-spacing: 3px;
    font-weight: 400;
    display: inline-block;
    margin: 0;
}

.logo a:hover {
    text-decoration: none;
}

header .logo h1 a {
    color: #333;
}

header .logo a:hover h1 {
    text-decoration: none;
}

header .top .row {
    margin-bottom: 0;
}

.select-list1 {
    width: 80px;
}

ul.list-style {
    list-style: none;
    margin: 0;
    padding-top: 5px;
}

ul.list-style li {
    display: inline-block;
}

ul.topmenu {
    list-style: none;
    margin: 0;
}

ul.topmenu li {
    display: inline;
}

ul.topmenu li a {
    font-size: 12px;
    color: #ddd;
    font-weight: 600;
}

ul.topmenu li a:hover {
    text-decoration: none;
}

ul.social-network {
    float: right;
    list-style: none;
    margin: 0;
}

ul.social-network li {
    display: inline;
    margin: 0 5px;
}

ul.social-network li a:hover {
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transition: all 1s ease-in-out;
}

.navbar {
    border-radius: inherit;
    min-height: inherit;
    padding: 0;
}

.navbar-nav>li>a {
    /* padding: 9px 20px 5px 20px; */
}

.navbar-nav>li.simple_label>p {
    /* padding: 9px 10px; */
    font-size: 14px;
    color: #46353b;
    font-weight: bold;
}

header .navigation {
    text-align: center;
}

header ul.navbar li {
    border: none;
    margin: 0;
}

header ul.navbar li a {
    border: none;
    font-weight: 400;
}

header ul.navbar li h3 {
    color: #000;
}

.navbar .navbar-nav>li>a {
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
    text-shadow: none;
    padding: 11px 40px;
    letter-spacing: 1.5px;
}

.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:hover,
.navbar-inverse .navbar-nav>.open>a:focus {
    background-color: #fff;
    color: #000;
    text-decoration: none;
}

.navbar .navbar-nav>li>a:hover {
    background-color: #fff;
    color: #000;
    text-decoration: none;
}

.navbar .navbar-nav>li>ul.dropdown-menu>li>a {
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    text-shadow: none;
    font-size: 12px;
}

.navbar .navbar-nav>li>ul.dropdown-menu2>li>a {
    font-size: 18px;
    text-transform: uppercase;
    color: #fff;
    text-shadow: none;
    font-size: 12px;
}


header ul.navbar-nav li a i {
    color: #656565;
}

header ul.navbar-nav li a:hover i {
    color: #000;
}

header ul.navbar-nav li.active a i {
    color: #000;
}

header ul.navbar-nav li ul.dropdown-menu li a i {
    color: #000;
}

header ul.navbar-nav li ul.dropdown-menu2 li a i {
    color: #000;
}


header ul.navbar-nav li.active {
    background-color: #fff;
    color: #000;
}

.navbar .navbar-nav a:hover {
    background: none;
}

.navbar .navbar-nav>.active>a,
.navbar .navbar-nav>.active>a:hover {
    font-weight: 600;
    color: #000;
    background-color: #fff;
}

.navbar .navbar-nav>.active>a:active,
.navbar .navbar-nav>.active>a:focus {
    background: none;
    outline: 0;
    font-weight: 600;
}

.navbar .navbar-nav li .dropdown-menu {
    z-index: 1000;
}

.navbar .navbar-nav li .dropdown-menu2 {
    z-index: 1000;
}


header ul.navbar-nav li ul {
    z-index: 1000;
    margin-top: 20px;
}

header ul.navbar-nav li ul li ul {
    margin: 0px 0 0 1px;
}

header ul.navbar-nav li ul li ul li {
    padding: 3px 0;
}

.dropdown-menu .dropdown i {
    position: absolute;
    right: 0;
    margin-top: 3px;
    padding-left: 20px;
}

.dropdown-menu2 .dropdown i {
    position: absolute;
    right: 0;
    margin-top: 3px;
    padding-left: 20px;
}


.navbar-inverse {
    background-color: #000 !important;
}

.navbar {
    margin-bottom: 0px;
    border: none;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
    border: none;
    display: flex;
    justify-content: space-between;
}

.navbar .navbar-nav>li>.dropdown-menu:before {
    /* display: inline-block; */
    display: flex;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-bottom-color: none;
    content: none;
}

.navbar .navbar-nav>li>.dropdown-menu2:before {
    /* display: inline-block; */
    display: flex;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-bottom-color: none;
    content: none;
}


.navbar .navbar-nav>li>.dropdown-menu:after {
    /* display: inline-block; */
    display: flex;
    border-right: none;
    border-bottom: none;
    border-left: none;
    content: '';
}

.navbar .navbar-nav>li>.dropdown-menu2:after {
    /* display: inline-block; */
    display: flex;
    border-right: none;
    border-bottom: none;
    border-left: none;
    content: '';
}


ul.navbar li.dropdown ul li ul.dropdown-menu {
    margin-left: 0px;
}

ul.navbar li.dropdown ul li ul.dropdown-menu2 {
    margin-left: 0px;
}

ul.navbar li.dropdown ul li i {
    margin-right: 20px;
}

select.selectmenu {
    display: none;
}

.search-clm {
    float: right;
    width: 48%;
}

.search-append .search-icon {
    position: absolute;
    top: 6px;
    right: 10px;
}

.top.affix {
    top: 0px;
    width: 100%;
}

.affix {
    z-index: 9999999;
    background-color: #fff;
}

.search {
    float: right;
    margin: 35px 0 0;
    padding-bottom: 0;
}

ul.general {
    list-style: none;
    margin-left: 0;
}

ul.link-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.link-list li {
    margin: 0;
    padding: 2px 0 2px 0;
    list-style: none;
}

ul.link-list li i {
    font-size: 20px;
    padding-right: 8px;
    color: #000000;
    width: 26px;
}

ul.link-list li span {
    position: relative;
    top: -2px;
    font-size: 13px;
}

.link-list footer ul.link-list li a {
    color: #444;
    font-weight: 300;
}

h4.heading {
    font-weight: 300;
}

h4.heading strong {
    font-weight: 700;
}

.heading {
    margin-bottom: 20px;
}

.heading {
    position: relative;
}

aside .widgetheading {
    position: relative;
    text-transform: none;
    font-weight: 600;
    letter-spacing: -1px;
    margin-bottom: 20px;
}

.filter-list {
    margin-top: 20px;
}

a i.icon-bglight,
a i.icon-bgdark,
a i.icon-bgprimary,
a i.icon-bgwarning,
a i.icon-bgsuccess,
a i.icon-bginverse,
a i.icon-bgdanger {
    display: inline-block;
}

a i.icon-bglight:hover,
a i.icon-bgdark:hover,
a i.icon-bgprimary:hover,
a i.icon-bgwarning:hover,
a i.icon-bgsuccess:hover,
a i.icon-bginverse:hover,
a i.icon-bgdanger:hover {
    color: #444;
}

[class^="icon-"].icon-white,
[class*=" icon-"].icon-white {
    color: #fff;
}

[class^="icon-"],
[class*=" icon-"] {
    color: #404040;
}

a [class^="icon-"]:hover,
a:hover [class^="icon-"],
a [class*=" icon-"]:hover,
a:hover [class*=" icon-"] {
    text-decoration: none;
}

ul.the-icons {
    list-style: none;
    margin-left: 0;
}

.demoicon [class^="icon-"],
.demoicon [class*=" icon-"],
[class^="icon-"].left,
[class*=" icon-"].left {
    float: left;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bbbbbb;
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #bbbbbb;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #bbbbbb;
}

input[type=text] {
    border-radius: 0px !important;
    -moz-box-shadow: inset 0 0 0px #fff !important;
    -webkit-box-shadow: inset 0 0 0px #fff !important;
    box-shadow: inset 0 0 0px #fff !important;
    outline: none !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
}


.search-clm .input-append input[class*="span"] {
    padding: 0px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

.topsearch-c {
    background-color: #ffffff;
    position: relative;
    width: 97%;
}

.search-append {
    position: relative;
}

.search-append .search-box {
    padding: 7px 10px;
    border: 1px solid #c9c9c9;
    width: 100%;
    text-align: left;
    font-size: 14px;
    color: #000;
    outline: 0;
}

.search-append .fa-search {
    font-size: 18px;
    position: absolute;
    right: 2px;
    top: 1px;
    padding: 10px;
    background-color: transparent;
    pointer-events: none;
    font-size: 18px;
    color: #c9c9c9;
}

.clearable.b_search {
    position: relative;
}

.clearable.b_search .clearable__clear {
    right: 10px;
    top: 5px;
}

.clearable .clearable__clear {
    /* display: none; */
    position: absolute;
    right: 40px;
    top: 5px;
    user-select: none;
    cursor: pointer;
}

.clearable input::-ms-clear {
    display: none;
}

.clearable .clearable__clear .close-icon {
    color: #9a9a9a;
    font-size: 16px;
}

.hdr-top {
    position: relative;
    min-height: 90px;
}

.logo {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1;
}

.logo a {
    display: flex;
    align-items: center;
    padding: 27px 0px;
}

.log-regi-cur {
    float: right;
    display: block;
    height: auto;
    padding-bottom: 0;
    overflow: visible;
    padding-top: 7px;
}

.menu-trigger {
    float: left;
    position: relative;
    z-index: 9;
}

.menu-trigger i {
    font-size: 28px;
    color: #000;
    padding: 18px 9px 14px 12px;
}

header ul.dropdown-menu {
    padding: 0% 3% 1% 4%;
}

header ul.dropdown-menu2 {
    padding: 0% 3% 1% 4%;
}

.dropdown {
    position: initial;
}

.dropdown-toggle::after {
    display: none;
}

/*Filter Over*/

.ac-section {
    padding-right: 9px;
    display: inline-block;
}

.ac-dd {
    display: inline-block;
    padding-right: 9px;
}

.ac-dd i {
    font-size: 14px;
}

.ac-dd i.u-ico {
    display: none;
}

.ac-dd i.u-dwn-ar {
    margin-left: 5px;
}

.ac-dd .dropdown-menu {
    padding: 0px;
    top: 140%;
    box-shadow: 0px 0px 1px 0px rgba(175, 175, 175, 0.5);
    padding: 5px;
    border-radius: 0px;
    min-width: 120px;
    left: -12px;
}

.ac-dd .dropdown-menu2 {
    padding: 0px;
    top: 140%;
    box-shadow: 0px 0px 1px 0px rgba(175, 175, 175, 0.5);
    padding: 5px;
    border-radius: 0px;
    min-width: 120px;
    left: -12px;
}

.ac-dd a.dropdown-toggle {
    text-decoration: none;
}

.ac-dd a:hover {
    text-decoration: none;
}

.ac-dd .dropdown-menu a {
    display: block;
    padding: 5px 8px;
    border-radius: 0px;
    text-decoration: none;
}

.ac-dd .dropdown-menu2 a {
    display: block;
    padding: 5px 8px;
    border-radius: 0px;
    text-decoration: none;
}


.ac-dd .dropdown-menu a:hover {
    text-decoration: none;
    font-weight: bold;
}

.ac-dd .dropdown-menu2 a:hover {
    text-decoration: none;
    font-weight: bold;
}


.ac-dd .dropdown-menu:before {
    position: absolute;
    top: 1px;
    left: 23px;
    width: 11px;
    height: 11px;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    background-color: white;
    content: " ";
    border-top: 0;
    border-right: 0;
    box-shadow: 2px -2px 2px 0 rgba(217, 217, 217, 0.5);
    z-index: 113;
    transition: left 250ms;
}

.ac-dd .dropdown-menu2:before {
    position: absolute;
    top: 1px;
    left: 23px;
    width: 11px;
    height: 11px;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    background-color: white;
    content: " ";
    border-top: 0;
    border-right: 0;
    box-shadow: 2px -2px 2px 0 rgba(217, 217, 217, 0.5);
    z-index: 113;
    transition: left 250ms;
}

.curncy .dropdown-menu:before {
    position: absolute;
    top: 1px;
    right: 13%;
    width: 11px;
    height: 11px;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    background-color: white;
    content: " ";
    border-top: 0;
    border-right: 0;
    box-shadow: 2px -2px 2px 0 rgba(217, 217, 217, 0.5);
    z-index: 113;
    transition: left 250ms;
}

.curncy .dropdown-menu2:before {
    position: absolute;
    top: 1px;
    right: 13%;
    width: 11px;
    height: 11px;
    transform-origin: 0 0;
    transform: rotate(-45deg);
    background-color: white;
    content: " ";
    border-top: 0;
    border-right: 0;
    box-shadow: 2px -2px 2px 0 rgba(217, 217, 217, 0.5);
    z-index: 113;
    transition: left 250ms;
}


.curncy .cur_sel {
    font-size: 12px;
}


.curncy {
    display: inline-block;
}

.curncy i {
    font-size: 14px;
}

.curncy i.u-dwn-ar {
    margin-right: 5px;
}

.curncy .dropdown-menu {
    padding: 0px;
    top: 140%;
    max-width: 80px;
    min-width: 70px;
    box-shadow: 0px 0px 1px 0px rgba(175, 175, 175, 0.5);
    padding: 5px;
    border-radius: 0px;
    right: 0px;
    left: -25px !important;
    left: initial;
}

.curncy .dropdown-menu2 {
    padding: 0px;
    top: 140%;
    max-width: 80px;
    min-width: 70px;
    box-shadow: 0px 0px 1px 0px rgba(175, 175, 175, 0.5);
    padding: 5px;
    border-radius: 0px;
    right: 0px;
    left: -25px !important;
    left: initial;
}

.curncy a.dropdown-toggle {
    text-decoration: none;
}

.curncy a:hover {
    text-decoration: none;
}

.curncy .dropdown-menu a {
    display: block;
    padding: 5px 8px;
    border-radius: 0px;
    text-decoration: none;
    font-size: 12px;
}

.curncy .dropdown-menu2 a {
    display: block;
    padding: 5px 8px;
    border-radius: 0px;
    text-decoration: none;
    font-size: 12px;
}

.curncy .dropdown-menu a:hover {
    text-decoration: none;
    font-weight: bold;
}

.curncy .dropdown-menu2 a:hover {
    text-decoration: none;
    font-weight: bold;
}

.fa-heart-o {
    cursor: pointer;
}

.fav-section {
    padding-right: 9px;
}

.fav-section i {
    font-size: 13px;
}

.nput-append .btn {
    border-radius: 0px;
}

.mb {
    position: relative;
    display: block
}

a.quick_view {
    line-height: 20px;
    cursor: pointer;
    color: #878787;
    position: absolute;
    bottom: 0px;
    background: rgba(237, 238, 237, 0.7);
    padding: 2px 7px;
    left: 0px;
    font-size: 14px;
}

i.fa-heart-o {
    font-size: 16px;
}

i.fa-heart-o:hover:before {
    content: "\f004";
    color: #bb2222;
}

.hvr-bg-ef:hover {
    background: #595959 !important;
}


#mob-menu-expand {
    display: none;
}

.dropdown-menu {
    border: inherit;
}

.dropdown-menu2 {
    border: inherit;
}


.dropdown-item.active,
.dropdown-item:active {
    background-color: #000;
}

.custom-menu {
    width: 100%;
    /*margin: 0 auto;*/
    position: unset;
}

@media (max-width:2880px) {
    header ul.dropdown-menu {
        padding: 0% 27% 1% 27.7%;
    }

    header ul.dropdown-menu2 {
        padding: 0% 27% 1% 27.7%;
    }
}

@media (max-width:1600px) {
    header ul.dropdown-menu {
        padding: 0% 4% 1% 3.5%;
    }

    header ul.dropdown-menu2 {
        padding: 0% 4% 1% 3.5%;
    }
}

@media (max-width:1440px) {
    header ul.dropdown-menu {
        padding: 0% 3% 1% 10%;
    }

    header ul.dropdown-menu2 {
        padding: 0% 3% 1% 10%;
    }
}

@media (max-width: 1369px) {
    header ul.dropdown-menu {
        padding: 0% 3% 1% 3%;
    }

    header ul.dropdown-menu2 {
        padding: 0% 3% 1% 3%;
    }
}

@media (max-width:767px) {

    /* FILTER :: if you hide 1024 to 768 then go bootsrtap.css line 4194 change 1025 to 768*/
    .mo-filter-sort {
        display: block;
    }

    .mobile-filter-close {
        display: block;
    }

    .hide-mob {
        display: none !important;
    }

    .filter-nav {
        display: initial;
        float: left;
        /* width: 100%; */
        background: #f8f8f8;
        position: absolute;
        left: 0px;
        padding: 0px;
        z-index: 999;
    }

    .sort-nav {
        position: absolute;
        right: 0px;
        float: right;
        width: calc(49.8% - 15px);
        margin-right: 15px;
        display: initial;
        padding: 0px;
    }

    #mySort .nav>li.dropdown.show .dropdown-menu {
        width: 100%;
        background: #fefefe;
        webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
        z-index: 9999;
        position: absolute;
    }

    #mySort .nav>li.dropdown.show .dropdown-menu2 {
        width: 100%;
        background: #fff;
        webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
        z-index: 9999;
        position: absolute;
    }

    .sort-nav .navbar-nav>li {
        /* float: none; */
    }

    .sort-nav .navbar-nav {
        /* margin: 0px;  */
    }

    #mySort ul.nav li.dropdown a {
        font-size: 14px;
    }

    #myFilter,
    #mySort {
        padding: 0px;
        border: 0px;
    }

    #myFilter .navbar-collapse {
        padding: 0px;
    }

    #myFilter .navbar-nav {
        margin: 0;
    }

    #myFilter .navbar-nav .open .dropdown-menu {
        position: absolute;
        padding: 1rem 2rem;
        background: #ffffff;
    }

    #myFilter .navbar-nav .open .dropdown-menu2 {
        position: absolute;
        padding: 1rem 2rem;
        background: #ffffff;
    }

    header ul.dropdown-menu {
        padding: 0% 1% 1% 4%;
        width: 100%;
    }

    header ul.dropdown-menu2 {
        padding: 0% 1% 1% 4%;
    }
}

@media (max-width:1024px) {

    /* Other changes */
    .navbar .navbar-nav>li>a {
        padding: 12px 30px;
        /* padding: 8px 30px; */
        letter-spacing: normal;
    }

    .fluid-reset-1024 {
        padding: 0px;
    }

    .custom-menu .navbar-nav {
        margin-left: -32px;
    }
}

@media (max-width:768px) {

    .navbar .custom-menu .nav>li>a {
        font-size: 12px;
        text-transform: uppercase;
        color: #fff;
        text-shadow: none;
        padding: 12px 22px;
        letter-spacing: 1px;
    }

    .navbar .custom-menu .nav>li>a:hover {
        color: #000;
    }

    .custom-menu .navbar-nav {
        margin-left: -22px;
    }
}

@media (max-width:767px) {

    .custom-menu .navbar-nav {
        display: none;
    }

    .search-clm {
        width: 100%;
        margin: 0 auto;
        float: none;
    }

    .search-append .search-box .search-icon {
        padding: 9px 10px;
        width: 85px;
    }

    .search-append {
        margin: 15px;
    }

    .for-fullmenu-1024.container {
        width: 100%;
    }

    .ac-dd .log-text {
        display: none;
    }

    .log-text:hover {
        text-decoration: none;
        color: red;
    }

    .login-text-desktop {
        text-decoration: none;
    }

    .menu-trigger i,
    .menu-trigger img {
        padding: 18px 9px 14px 0px;
        cursor: pointer;
    }

    .logo a {
        padding: 18px 0px;
    }

    .hdr-top {
        min-height: auto;
    }

    .log-regi-cur {
        padding-top: 20px;
    }

    .ac-dd .dropdown-menu {
        left: -100%;
    }

    .ac-dd .dropdown-menu2 {
        left: -100%;
    }

    header .logo h1 {
        font-size: 1.7em;
        letter-spacing: 2px;
    }

    .container-desp {
        width: 100%;
        padding: 0px 0px;
    }

    .container-desp small {
        font-size: 84%;
    }

    footer h5.widgetheading {
        letter-spacing: normal;
    }

    .ac-dd i.u-ico {
        display: inline-block;
    }

    .navbar-inverse {
        background-color: #fff !important;
    }

    #mob-menu-expand {
        display: block;
    }

    .custom-menu .navbar-nav {
        margin-left: 0px;
    }

    .filter-nav .dropdown-menu .filter-title .b_search input {
        width: 75.5%;
    }

    .filter-nav .dropdown-menu2 .filter-title .b_search input {
        width: 75.5%;
    }
}

@media (max-width:425px) {

    .curncy {
        display: none;
    }

    .ac-dd {
        padding-right: 5px;
    }

    .fav-section {
        padding-right: 0px;
    }

    .fav-section a {
        padding: 0px !important;
    }

    .fav-section a i.fa-heart-o {
        font-size: 15px;
    }

    .ac-dd .dropdown-menu {
        left: -400%;
    }

    .ac-dd .dropdown-menu2 {
        left: -400%;
    }

    .ac-dd .dropdown-menu:before {
        left: 80px;
    }

    .ac-dd .dropdown-menu2:before {
        left: 80px;
    }
}

@media (min-width:1025px) and (max-width: 1200px) {
    .navbar .custom-menu .nav>li>a {
        padding: 12px 27px;
    }
}

@media (min-width:769px) and (max-width: 1023px) {

    .navbar .custom-menu .nav>li>a {
        font-size: 12px;
        text-transform: uppercase;
        color: #fff;
        text-shadow: none;
        padding: 11px 22px;
        letter-spacing: 1px;
    }

    .search-clm {
        float: right;
        /* width: 40%; */
        width: 37%;
    }

}

@media (min-width: 979px) and (max-width: 1200px) {
    #wrapper.boxed {
        width: 1000px;
        margin: 0 auto;
    }
}

@media (min-width: 768px) and (max-width: 979px) {
    #wrapper.boxed {
        width: 784px;
        margin: 0 auto;
    }

    header .logo {
        margin-top: 0;
        float: none;
    }

}

@media (max-width: 768px) {
    #wrapper.boxed {
        width: 90%;
        margin: 0 auto;
    }

    ul.nav li.dropdown ul li a {
        color: #fff;
    }

    header ul.nav li {
        color: #fff;
    }

    body {
        padding-right: 0;
        padding-left: 0;
    }

    header .logo {
        float: none;
        text-align: center;
    }

}

@media (max-width: 480px) {
    .input-append button.btn {
        float: right;
    }

    header .logo {
        margin: 0 auto;
        text-align: center;
        float: none;
    }

    header .logo img {
        margin-bottom: 10px;
    }

    .top p.topcontact {
        text-align: center;
    }

}

@media (max-width: 360px) {
    .sort-nav .sort-fil-arw {
        display: none;
    }

    header .logo h1 {
        font-size: 22px;
    }

    header .login-register {
        right: 0;
    }

    .ac-dd {
        padding-right: 0;
    }
}

@media screen and (max-width: 767px) {
    .dropdown-menu>li {
        display: block;
    }

    .dropdown-menu2>li {
        display: block;
    }

    .list-style .col-lg-1 {
        width: auto;
        margin-top: 10px;
    }

    .topsearch-c {
        left: 0px;
    }

    header .logo h1 {
        top: 0px;
    }

    .dropdown-menu>li {
        display: block;
    }

    .dropdown-menu2>li {
        display: block;
    }

    .list-style .col-lg-1 {
        width: auto;
        margin-top: 10px;
    }

    .custom-menu {
        /*width:100%;
    margin: 0 auto;*/
        position: unset;
    }

    .topsearch-c {
        left: 0px;
    }

    header .logo h1 {
        top: 0px;
    }

    ul.navbar-nav li.dropdown ul li a {
        color: #000 !important;
    }

    .dropdown-menu>li {
        display: block !important;
    }

    .dropdown-menu2>li {
        display: block !important;
    }

    header ul.navbar-nav li ul li ul {
        padding: 0px;
    }

    .navbar .navbar-nav>li>a {
        padding: 5px 0px;
    }

    .topsearch-c {
        width: 100%;
    }
}


.navbar-nav>li.dropdown.show {
    position: static;
}

.navbar-nav>li.dropdown.show .dropdown-menu {
    display: table;
    width: 100%;
    left: 0;
    right: 0;
    top: 37px;
    box-shadow: 8px 3px 4px 0px rgb(175 175 175 / 50%);
}

.navbar-nav>li.dropdown.show .dropdown-menu2 {
    display: table;
    width: 100%;
    left: 0;
    right: 0;
    top: 37px;
    box-shadow: 8px 3px 4px 0px rgb(175 175 175 / 50%);
}

.dropdown-menu>li {
    display: table-cell;
    padding: 0px 0px;
}

.dropdown-menu2>li {
    display: table-cell;
    padding: 0px 0px;
}

ul.navbar-nav li.dropdown a {
    color: #fff;
}

ul.navbar-nav li.dropdown ul li a {
    color: #000;
    text-align: left;
    font-size: 12px;
}

header ul.navbar-nav li {
    list-style: none;
    color: #fff;
    text-align: left;
    font-size: 11px;
    margin-right: 50px;
}

header ul.navbar-nav li h3 {
    font-size: 12px;
    line-height: 25px;
    margin: 0px;
    font-family: 'helveticaneuemedium', Arial, Helvetica, sans-serif;
    font-weight: 600;
    padding: 20px 0px;
    color: #000;
}

.demo-container {
    background-color: #000;
    color: #fff;
    /* text-align: center; */
    /* padding: 3PX; */
}

.demo-container .parent .title-sub2,
.demo-container .parent .title {
    color: #fff;
}

.sidebar-navigation .quick-link {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    padding-top: 10px;
}

.sidebar-navigation .quick-link li {
    color: #000;
    margin-bottom: 20px;
    border-bottom: 1px solid #d5d0d0;
}

.sidebar-navigation .quick-link li a {
    color: #000;
    padding-top: 10px;
}

.sidenav .box-check {
    font-size: 13px;
    font-weight: lighter;
    margin: 5px;
    color: #000;
}

.mboile-sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 50px;
}

.mboile-sidenav .closebtn {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 36px;
    margin-left: 50px;
}


/* Css by garv  */

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    /* z-index: 1; */
    top: 0;
    left: 0;
    background-color: #ffffff;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: 0.5s;
    padding-top: 60px;
    text-align: center;
    z-index: 9999;
    color: #000;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #000000;
    display: block;
    transition: 0.3s;

}

.sidenav a:hover {
    color: #ffffff;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 36px;
    /* margin-left: 50px; */
}

li {
    list-style: none;
    color: #000;
}

.sidenav a,
.dropdown-btn {
    padding: 6px 8px 6px 16px;
    text-decoration: none;
    font-size: 20px;
    color: #818181;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    outline: none;
}

/* On mouse-over */
.sidenav a:hover,
.dropdown-btn:hover {
    color: #f1f1f1;
}

/* Main content */
.main {
    margin-left: 200px;
    /* Same as the width of the sidenav */
    font-size: 20px;
    /* Increased text to enable scrolling */
    padding: 0px 10px;
}

/* Add an active class to the active dropdown button */
.active {
    background-color: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
}

/* Dropdown container (hidden by default). Optional: add a lighter background color and some left padding to change the design of the dropdown content */
.dropdown-container {
    display: none;
    background-color: #262626;
    padding-left: 8px;
}

/* Optional: Style the caret down icon */
.fa-caret-down {
    /* float: right; */
    padding-right: 8px;
}

.close-icon {
    margin-left: -10px;
}

/* Mobile side menu css  */

#wrapper {
    width: 100%;
    margin: 0;
    padding: 0;
}

#wrapper.boxed {
    width: 1230px;
    overflow: hidden;
    margin: 0 auto;
    padding: 0;
    background: #fff;
}

.row {
    margin-bottom: 0px;
}

.row .row {
    margin-bottom: 30px;
}

.row.nomargin {
    margin-bottom: 0;
}

header .top .row {
    margin-bottom: 0;
}


#featured .row {
    margin-bottom: 0;
}

.hdr-top {
    position: relative;
    min-height: 90px;
}

#mob-menu-expand {
    display: none;
}

.menu-trigger {
    float: left;
    position: relative;
    z-index: 9;
}


.menu-trigger i {
    font-size: 28px;
    color: #000;
    padding: 18px 9px 14px 12px;
}

.demo-container {
    background-color: #000;
    color: #fff;
    /* text-align: center; */
    padding: 3PX;
}


.demo-container .parent .title-sub2,
.demo-container .parent .title {
    color: #fff;
}

#menu-display {
    display: none;
}

@media (min-width: 979px) and (max-width: 1200px) {
    #wrapper.boxed {
        width: 1000px;
        margin: 0 auto;
    }
}


@media (min-width: 768px) and (max-width: 979px) {
    #wrapper.boxed {
        width: 784px;
        margin: 0 auto;
    }

    header .logo {
        margin-top: 0;
        float: none;
    }

    select.selectmenu {
        display: block;
        width: 100%;
        margin-top: 0;
        color: #111;
        margin-right: 20px;
        line-height: 30px;
        font-size: 13px;
    }

    select.selectmenu .bold {
        font-weight: bold;
    }

    .camera_caption a.btn {
        margin-bottom: 10px;
    }

    a.detail {
        background: none;
        width: 100%;
    }

    /* footer .widget form input#appendedInputButton {
       display: block;
        width: 91%;
        -webkit-border-radius: 4px 4px 4px 4px;
        -moz-border-radius: 4px 4px 4px 4px;
        border-radius: 4px 4px 4px 4px;
    } */
    footer .widget form .input-append .btn {
        display: block;
        width: 100%;
        padding-right: 0;
        padding-left: 0;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
}


@media (max-width: 768px) {
    #wrapper.boxed {
        width: 90%;
        margin: 0 auto;
    }

    #menu-display {
        display: block;
    }

    .hdr-top {
        min-height: auto;
    }

    #mob-menu-expand {
        display: block;
    }

    .menu-trigger i,
    .menu-trigger img {
        padding: 18px 9px 14px 0px;
        cursor: pointer;
    }
}

/* #menu {
    display: none;
} */

/* Global Styles */
body {
    font-family: sans-serif;
    background-color: #1a1a1a;
    /* Dark background for contrast */
    color: white;
    margin: 0;
    padding: 0;
    height: 100vh;
}

/* Sidebar Styles */
.sidebar {
    position: fixed;
    font-family: sans-serif;
    top: 0;
    left: -250px;
    /* Initially offscreen */
    width: 250px;
    height: 100%;
    background-color: #ffffff;
    transition: left 0.3s ease-in-out;
    z-index: 1000;
    overflow-y: auto;
    overflow-x: hidden
}

.sidebar.active {
    left: 0;
    /* Sidebar becomes visible when active */
}

.close-btn {
    font-size: 30px;
    color: rgb(0, 0, 0);
    background: none;
    border: none;
    position: absolute;
    top: 1%;
    right: -38%;
    cursor: pointer;
    opacity: .7;
    /* border: 1px solid black; */
}

.close-btn:hover {
    color: #161414;
    opacity: .5;
}

/* Menu Container */
.menu-container {
    margin-top: 60px;
    padding-left: 20px;
}

.menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

.menu>li>a {
    padding: 12px 15px;
    display: block;
    font-size: 16px;
    color: white;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.menu>li>a:hover {
    background-color: #444;
}

/* Submenu Styling */
.submenu {
    list-style: none;
    margin: 0;
    padding: 0;
    display: none;
    /* Hide submenus by default */
    background-color: #ffffff;
    padding-left: 15px;
}

.submenu.active {
    display: block;
    /* Show submenu when active */
}

.submenu>li>a {
    padding: 10px 15px;
    font-size: 14px;
    color: #000000;
    text-decoration: none;
    cursor: pointer;
}

/* .submenu>li>a:hover {
    background-color: #555;
} */

/* Nested Submenu */
.submenu .submenu {
    /* padding-left: 20px; */
    background-color: #ffffff;
    font-size: 16px;
    height: 100vh;

}

.submenu .submenu>li {
    padding: 7px;
    font-size: 14px;
    color: #000000;
    /* line-height: 1; */
}

/* .submenu .submenu>li:hover {
    background-color: #000000;
    color: white;
}

.submenu .submenu>li>a:hover {
    background-color: #000000;
    color: white;
} */

/* Scrollbar Styling */
.sidebar::-webkit-scrollbar {
    width: 5px;
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: #444;
    border-radius: 5px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
    background-color: #666;
}

.Main-category {
    margin: auto;
    color: #0088ff;
}

.divider {
    width: 100%;
    height: .7px;
    background-color: #000;
}

.subCateg-divider {
    margin: .5rem;
}

.active-subchild {
    background-color: #000000;
    color: white;
}

@media (max-width: 500px) {
    
    .navbar-nav>li.dropdown.show .dropdown-menu {
        top: 40px;
        /* left:-20px */
    }

    .ac-dd {
        display: inline-block;
        padding-right: 0px;
    }

    .ac-dd i {
        font-size: 14px;
    }

    .ac-dd i.u-ico {
        display: none;
    }

    .ac-dd i.u-dwn-ar {
        margin-left: 5px;
    }

    .ac-dd .log-text {}

    .ac-dd .dropdown-menu {
        padding: 0px;
        top: 140%;
        box-shadow: 0px 0px 1px 0px rgba(175, 175, 175, 0.5);
        padding: 5px;
        border-radius: 0px;
        min-width: 120px;
        left: -12px;
    }

    .ac-dd a.dropdown-toggle {
        text-decoration: none;
    }

    .ac-dd a:hover {
        text-decoration: none;
    }

    .ac-dd .dropdown-menu a {
        display: block;
        padding: 5px 8px;
        border-radius: 0px;
        left: -40px;
        text-decoration: none;
    }

    .ac-dd .dropdown-menu a:hover {
        text-decoration: none;
        font-weight: bold;
    }

    .ac-dd .dropdown-menu:before {
        position: absolute;
        top: 1px;
        left: -23px;
        width: 11px;
        height: 11px;
        transform-origin: 0 0;
        transform: rotate(-45deg);
        background-color: white;
        content: " ";
        border-top: 0;
        border-right: 0;
        box-shadow: 2px -2px 2px 0 rgba(217, 217, 217, 0.5);
        z-index: 113;
        transition: left 250ms;
    }

    .ac-dd .dropdown-menu .show {
        left: -100px;
    }

    .active-tem {
        background-color: #000000;
        color: white;
    }

    .active-tem a {
        background-color: #000000;
        color: white;
    }
}