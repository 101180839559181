* {
    margin: 0;
    padding: 0;
    outline: none;
    font-family: 'BrownLight';
}

:root {
    --border-color: #c8c8c8;
}

h2 {
    font-size: 38px;
    text-align: center;
}

a {
    text-decoration: none;
    color: #000;
}

img {
    max-width: 100%;
}

.container {
    max-width: 1260px;
    width: 95%;
    margin: 0 auto;
}

.tab-main-block {
    text-align: center;
}

.tab-main-block a {
    display: inline-block;
    margin: 25px;
    text-transform: uppercase;
    transition: .1s;
    padding: 1px 3px;
    font-family: 'Brown';
}

.tab-main-block a:hover {
    color: #fff;
    background: #000;
}

.banner-image-block {
    width: 70%;
}


.arrow-prev {
    background-color: gray !important;
    margin-right: 86%;
    margin-top: 80%;
    border-radius: 50%;
}

.arrow-next {
    background-color: gray !important;
    margin-left: 86%;
    margin-top: 80%;
    border-radius: 50%;
}

.banner-text-block {
    width: 30%;
    text-align: center;
}

.banner-main-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid var(--border-color);
}

.banner-text-block .banner-block-items-content h2 {
    font-size: 45px;
    font-family: 'Brown';
}

.banner-text-block .banner-block-items-content a {
    text-decoration: underline;
    font-family: 'Brown';
}

.banner-image-block img {
    width: 100%;
    display: block;
}

.banner-block-items-content p {
    font-size: 18px;
    text-transform: uppercase;
    margin: 0 0 15px;
    font-family: 'BrownLight';
}

.banner-text-block-items-content {
    margin: 0 15px;
}

.product-block-items-content img {
    width: 100%;
    display: block;
}

.product-slider_next img {
    float: right;
}

.product-slider-btn {
    background: transparent;
    position: absolute;
    z-index: 9;
    border: none;
    cursor: pointer;
}

.product-slider-btn img {
    width: 15px;
    display: block;
   
}

.product-slider_prev img{
    left: 212%;
    position: absolute;
}

.product-slider_next img{
    left: 96%;
    position: absolute;
}

button.product-slider_next {
    right: -3%;
    top: 50%;
    transform: translateY(-50%);
}

button.product-slider_prev {
    left: -3%;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
}

.product-block-items-content {
    text-align: center;
}

.product-block-items-content h3 {
    margin: 10px 0;
}

.product-header {
    text-align: center;
}

.products-main-block,
.what-to-buy-main-block {
    margin: 50px 0;
}

.product-header h2 {
    font-size: 38px;
}

.product-header h2 span {
    font-style: italic;
}

.product-header p {
    text-decoration: underline;
    font-size: 17px;
    padding: 20px 0;
}

.product-header h4 img {
    width: 18px;
    margin-left: 10px;
}

.product-header h4 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    font-style: italic;
}

.product-slider-items-main-block {
    padding: 50px 0 0;
}

.product-block-items-content {
    margin: 0 30px;
}

.what-to-buy-header h2 {
    position: relative;

}

.what-to-buy-header h2::after {
    content: '';
    position: absolute;
    width: 50%;
    height: 2px;
    background: #000;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.what-to-buy-header h2 span {
    background: #fff;
    padding: 0 18px;
}

.what-to-buy-block-items {
    width: 50%;
}

.what-to-buy-inner-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.what-to-buy-image-block {
    width: 60%;
}

.what-to-buy-text-block {
    width: 40%;
    margin-left: -60px;
    background: #ffffff78;
    text-align: center;
}

.reverse-inner-block {
    flex-direction: row-reverse;
}

.reverse-inner-block .what-to-buy-text-block {
    margin-left: 0;
    margin-right: -60px;
    z-index: 9;
}

.what-to-buy-items-main-block {
    display: flex;
    flex-wrap: wrap;
}

.full-width-block .what-to-buy-image-block,
.full-width-block .what-to-buy-text-block {
    width: 100%;
}

.full-width-block .what-to-buy-text-block {
    background: #fff;
    margin-top: -20px !important;
    width: 70%;
    margin: 0 auto;
}

.full-width-block .what-to-buy-text-block h4,
.full-width-block .what-to-buy-text-block p {
    font-size: 34px;
}

.what-to-buy-block-items-content {
    margin: 20px;
}

.product-alter-block .what-to-buy-text-block {
    margin-left: 0;
    text-align: center;
}

.product-alter-block .what-to-buy-text-block h4 {
    font-size: 28px;
    font-family: 'BrownLight';
    text-align: center;
}

.product-alter-block .what-to-buy-text-block h4 span {
    font-size: 32px;
}

.product-alter-block .what-to-buy-text-block p {
    font-style: italic;
    font-size: 20px;
    font-weight: 400;
}

.product-alter-block .reverse-inner-block .what-to-buy-text-block {
    text-align: right;
    margin-right: 0;
}

.product-alter-block {
    border: 1px solid var(--border-color);
    margin-top: 6em;
    padding: 12px;
}


.what-to-buy-items-header {
    display: flex;
    justify-content: center;
}

.what-to-buy-items-header h2 {
    background-color: white;
    color: #0f0f0f;
    color: white;
    padding: 0 30px;
}

.what-to-buy-items-header {
    height: 2px;
    /* background-color: #000; */
    transform: translate(-50%, -50%);
    /* z-index: -1; */
}

.what-to-buy-items-header h2 {
    /* background-color:white; */
    background-color: black;
    margin-top: -20px;
    height: 62px;
}

.what-to-buy-product-block-items {
    width: 50%;
    margin-top: 60px;
}

.what-to-buy-product-image-block img {
    /* width: 100%; */
    height: 350px;
    object-fit: cover;
}

.what-to-buy-product-image-block img {
    /* width: 100%; */
    /* height: 100%; */
    /* object-fit: cover; */
}

.what-to-buy-product-block-items-content {
    text-align: center;
    margin: 10px;
}

.what-to-buy-product-block-items-content h4 {
    font-size: 15px;
}

.what-to-buy-product-block-items-content span {
    font-size: 14px;
}

.what-to-buy-product-block-items-content h5 {
    font-size: 14px;
    font-weight: 400;
}

.what-to-buy-product-block {
    display: flex;
    flex-wrap: wrap;
    /* margin-top: 35px; */
}

.homeBuyNowImg {
    /* width: 309px; */
    /* height: 175px; */
}

.homepageLink {
    color: black !important;
    /* set the color to whatever you want */
    text-decoration: none !important;
    /* remove the underline */
}





/********************************************Media***********************************************/
@media only screen and (max-width: 1024px) {
    .what-to-buy-header h2::after {
        width: 70%;
    }

}

@media screen and (max-width: 412px)  {
    .what-to-buy-items-header {
        transform: translate(0%, 0%);
        /* position: absolute; */
        top: 40%;
    }

}

@media only screen and (max-width: 768px) {
    .product-slider_prev img {
        left: -109%;
    }
    .product-slider_next img {
        left: 104%;
    }

    .banner-text-block,
    .banner-image-block {
        width: 100%;
    }

    .banner-text-block {
        padding: 55px 0;
    }

    .tab-main-block a {
        margin: 25px 10px;
        font-size: 15px;
    }

    .what-to-buy-block-items {
        width: 100%;
    }

    .what-to-buy-product-image-block {
        height: auto;
    }

    .product-alter-block {
        margin-top: 2em;
    }
    
    

    .what-to-buy-items-header .what-to-buy-items-text {
        margin-top: 10px;
        color: #ffffff;
        padding: 5px 30px 10px;
        font-size: 19px;
        text-align: center;
    }

    .what-to-buy-items-header {
        transform: translate(0%, 0%);
        /* position: absolute; */
        top: 40%;
    }
    

    .product-alter-block .what-to-buy-text-block {
        margin-left: 0;
        text-align: center !important;
    }

    .what-to-buy-block-items {
        margin-top: -35px;
    }

    .product-block-items-content{
    }

    .product-block-items-content h3{
        width: 100%;
        font-size: 14px;
        text-align: center;
        word-break: normal;
        margin-left:20px;

    }
    .product-block-items-content p{
        font-size: 10px;
        text-align: center;
        margin-left: 40px;
    }

    .product-block-items-content .imageContainer{
        width: 140px;
        /* height: 100px; */
    }


}

@media only screen and (max-width: 575px) {
    .what-to-buy-items-header {
        transform: translate(0%, 0%);
        /* position: absolute; */
        top: 40%;
    }
    

    .what-to-buy-image-block,
    .what-to-buy-text-block {
        width: 100%;
        text-align: center !important;
    }

    .what-to-buy-image-block,
    .what-to-buy-text-block {
        width: 100%;
        margin: 0 auto !important;
        text-align: center !important;
    }

    .what-to-buy-header span {
        font-size: 27px;
    }

    .what-to-buy-header h2::after {
        width: 97%;
    }

    /* h2 {
        font-size: 32px !important;
    } */

    .full-width-block .what-to-buy-text-block h4 {
        margin-top: 20px;
        text-align: center !important;
        font-size: 20px;
    }

    .full-width-block .what-to-buy-text-block p {
        font-size: 16px;
    }

    .banner-block-items-content p {
        font-size: 16px;
    }

    .tab-main-block a {
        margin: 25px 6px;
        font-size: 13px;
    }

    .what-to-buy-items-text{
        font-size: .5rem;
    }
}


/* CSS BY SHIKHAR */

.product-slider-block .slick-prev {
    /* background-image: url('/public/images/homepage/left-arrow.png') !important; */
    /* background-image: unset !important; */
}

.product-slider-block .slick-next {
    /* background-image: unset !important; */
}

.imageContainer {
    width: 200px;
    height: 250px;
    overflow: hidden;
}

.imageContainer img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.fav-product-link {
    color: #000;
}

.fav-product-link:hover {
    text-decoration: none;
    color: #000;
}


/* Css by garv  */

.image-wrapper {
    margin-top: 10px;
}

.see-more-text{
    /* border-bottom: 1px solid black; */
    border: .5px solid black;
    opacity: 0.5;
    padding: 10px;
}

.image-credit {
    position: absolute;
    bottom: 10px;
    right: 1px;
    background-color: rgb(0 0 0 / 21%);
    color: white;
    padding: 2px 10px;
    font-size: 15px;
    /* border-radius: 5px; */
}
.image-credit a {
    text-decoration: none;
    color: white
}
.image-credit a:hover {
    text-decoration: none;
    color: white
}
.carousel-caption {
    right: 0px !important;
    bottom: -9px !important;
}

.banner-main-block {
    width: 1423px;
    height: 665px;
}